import { InputTextarea } from 'primereact/inputtextarea';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import cancerSlice, { postStep4, putStep4 } from '../../../../../Redux/cancerSlice';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function ProductCancerAddress() {

    const navigate = useNavigate();

    const { cancerText, languageSelected } = useSelector((state) => state.language);
    let { step2, warning } = cancerText

    const regInputPattern = languageSelected === 'TH' ? /^[ก-๏0-9_/\s]+$/g : /^[a-zA-Z0-9_/\s]+$/g
    const regInputPatternMsg = languageSelected === 'TH' ? 'โปรดกรอกภาษาไทยเท่านั้น!' : 'Must be English character!'

    const { provincesData, districtsData } = useSelector((state) => state.master);
    const { cancerId, cancerStep4, documentLanguage } = useSelector((state) => state.cancer);
    const { setCancerStep4, setStepActive, setAccordionCancerActive } = cancerSlice.actions;
    const [districts, setDistricts] = useState(cancerStep4 ? districtsData.filter(e => e.provinceCode === cancerStep4.province.code) : []);

    let xZipcodes = districts
        .map(e => e.postCode)
        .filter((value, index, array) => array.indexOf(value) === index)
        .map(e => ({ postCode: e }));

    const [zipCode, setZipCode] = useState(cancerStep4 ? xZipcodes : []);

    const defaultValues = {
        cancerId: cancerId,
        address: cancerStep4 ? cancerStep4.address : '',
        subDistrict: cancerStep4 ? cancerStep4.subDistrict : '',
        district: cancerStep4 ? cancerStep4.district : null,
        province: cancerStep4 ? cancerStep4.province : null,
        zipCodeData: cancerStep4 ? cancerStep4.zipCodeData : null
    }

    if (documentLanguage !== languageSelected) {
        sessionStorage.removeItem('cancerPremium')
        sessionStorage.removeItem('cancerStep1')
        sessionStorage.removeItem('cancerStep2')
        sessionStorage.removeItem('cancerStep3')
        sessionStorage.removeItem('cancerStep4')
        sessionStorage.removeItem('cancerStep5')
        sessionStorage.removeItem('cancerStep6')
        dispatch(setStepActive(0))
        dispatch(setAccordionCancerActive(0))
        navigate('/product/cancer')
    }

    const dispatch = useDispatch();
    const { control, formState: { errors }, handleSubmit, setValue } = useForm({ defaultValues });

    const onSubmit = (data) => {
        const params = data;
        params.cancerId = cancerId;
        params.districtId = data.district.id;
        params.provinceId = data.province.id;
        params.zipCode = data.zipCodeData.postCode;

        if (cancerStep4) {
            dispatch(putStep4(params));
        }
        else {
            dispatch(postStep4(params));
        }
    };

    const changeProvince = (event) => {
        let provinceCode = event.target.value.code;
        setDistricts(districtsData.filter(e => e.provinceCode === provinceCode));
        setValue('province', event.target.value);
        setValue('district', null);
        setValue('zipCode', null);
        setZipCode([]);
    }

    const changeDistrict = (event) => {
        const code = event.value.code
        let zipcodes = districts
            .filter(e => e.code === event.target.value.code)
            .map(e => ({ postCode: e.postCode }));
        setZipCode(zipcodes);
        setValue('district', event.target.value);
    }

    const getFormErrorMessage = (name) => {
        return errors[name] && <div><small className="p-error">{errors[name].message}</small></div>
    };

    return (
        <div className='box-product-cancer'>
            <form id='form-campaign' onSubmit={handleSubmit(onSubmit)}>
                <h2 className='text-red-600'>{step2.address.title}</h2><br />
                <div className="grid">
                    <div className="col-12 md:col-12 lg:col-12 flex flex-column gap-2">
                        <label className='font-bold text-sm'>{step2.address.address} <span className="text-red-600">*</span></label>
                        <span className="p-float-label">
                            <Controller name="address" control={control} rules={{ required: warning.address.address }} render={({ field, fieldState }) => (
                                <InputTextarea id={field.address} autoFocus={true} {...field} rows={4} className={classNames({ 'p-invalid': fieldState.invalid })} />
                            )} />
                            {getFormErrorMessage('address')}
                        </span>
                    </div>
                </div>
                <div className="grid mt-2">
                    <div className="col-12 md:col-6 lg:col-6 flex flex-column gap-2">
                        <label className='font-bold text-sm'>{step2.address.province} <span className="text-red-600">*</span></label>
                        <Controller name="province" control={control} rules={{ required: warning.address.province }} render={({ field, fieldState }) => (
                            <Dropdown optionLabel={languageSelected === "TH" ? "descTh" : "descEn"} options={provincesData} placeholder={step2.address.placeholderProvince} id={field.name} {...field} inputRef={field.ref} onChange={(e) => changeProvince(e)} className={classNames('w-full ' + { 'p-invalid': fieldState.invalid })} />
                        )} />
                        {getFormErrorMessage('province')}
                    </div>
                    <div className="col-12 md:col-6 lg:col-6 flex flex-column gap-2">
                        <label className='font-bold text-sm'>{step2.address.district} <span className="text-red-600">*</span></label>
                        <Controller name="district" control={control} rules={{ required: warning.address.district }} render={({ field, fieldState }) => (
                            <Dropdown optionLabel={languageSelected === "TH" ? "descTh" : "descEn"} filter filterBy="descTh,descEn" options={districts} placeholder={step2.address.placeholderDistrict} id={field.name} {...field} inputRef={field.ref} onChange={(e) => changeDistrict(e)} className={classNames('w-full ' + { 'p-invalid': fieldState.invalid })} />
                        )} />
                        {getFormErrorMessage('district')}
                    </div>
                </div>
                <div className="grid">
                    <div className="col-12 md:col-6 lg:col-6 flex flex-column gap-2">
                        <label className='font-bold text-sm'>{step2.address.subDistrict} <span className="text-red-600">*</span></label>
                        <Controller name="subDistrict" control={control} rules={{ required: warning.address.subDistrict }} render={({ field, fieldState }) => (
                            <InputText id={field.subDistrict} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                        )} />
                        {getFormErrorMessage('subDistrict')}
                    </div>
                    <div className="col-12 md:col-6 lg:col-6 flex flex-column gap-2">
                        <label className='font-bold text-sm'>{step2.address.zipCode} <span className="text-red-600">*</span></label>
                        <Controller name="zipCodeData" control={control} rules={{ required: warning.address.zipCode }} render={({ field, fieldState }) => (
                            <Dropdown optionLabel="postCode" options={zipCode} placeholder={step2.address.placeholderZipCode} filter showClear filterBy="postCode" id={field.name} {...field} inputRef={field.ref} className={classNames('w-full ' + { 'p-invalid': fieldState.invalid })} />
                        )} />
                        {getFormErrorMessage('zipCodeData')}
                    </div>
                </div>
                <div className="text-center mt-3">
                    <Button className='col-12 md:col-3 lg:col-3' type='submit' label={step2.address.button} severity="danger" />
                </div>
            </form>
        </div>
    )
}
import React, { useState } from 'react';
import { Image } from 'primereact/image';
import visa from '../../../../../Assets/images/cancer/paymentMethod/VISA-logo.png';
import masterCard from '../../../../../Assets/images/cancer/paymentMethod/Mastercard-logo.png';
import jcb from '../../../../../Assets/images/cancer/paymentMethod/JCB_logo.png';
import trueMoney from '../../../../../Assets/images/cancer/paymentMethod/TrueMoney.png';
import shopeePay from '../../../../../Assets/images/cancer/paymentMethod/ShopeePay.png';
import promptPay from '../../../../../Assets/images/cancer/paymentMethod/prompt-pay-logo.png';
import linePay from '../../../../../Assets/images/cancer/paymentMethod/LINEPay.png';
import unionPay from '../../../../../Assets/images/cancer/paymentMethod/UnionPay.png';

export default function ProductCancerPaymentMethod() {

    return (
        <div className='box-product-cancer'>
            <div className="grid payment-method-pc m-2">
                <div className="gap-2 col-12 md:gap-5 lg:col-12 flex">
                    <Image src={visa} alt="Image" width="120" />
                    <Image src={masterCard} alt="Image" width="90" />
                    <Image src={promptPay} alt="Image" width="120" />
                    <Image src={trueMoney} alt="Image" width="150" />
                    
                </div>
                <div className="gap-2 col-12 md:gap-5 lg:col-12 flex">
                    <Image src={jcb} alt="Image" width="90" />
                    <Image src={shopeePay} alt="Image" width="100" />
                    <Image src={linePay} alt="Image" width="120" />
                    <Image src={unionPay} alt="Image" width="100" />
                </div>
            </div>
        </div>
    );
}
import PuffLoader from "react-spinners/PuffLoader";

export default function ProgressLoading() {
    const override = {
        position: 'absolute',
        display: 'flex',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    };
    return (
        <div className="loading">
            <PuffLoader  color={"#FF3818"} loading={true} cssOverride={override} size={300} />
        </div>
    );
}

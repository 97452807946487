import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import cancerSlice, { postStep6, putStep6 } from '../../../../../Redux/cancerSlice';
import { useNavigate } from 'react-router-dom';

export default function ProductCancerCondition() {

    const navigate = useNavigate();

    const { cancerText, languageSelected } = useSelector((state) => state.language);
    let { step2, warning } = cancerText

    const { cancerId, cancerStep2, cancerStep6,documentLanguage } = useSelector((state) => state.cancer);
    const { setStepActive, setCancerStep6, setAccordionCancerActive } = cancerSlice.actions;

    const defaultValues = {
        agree1: cancerStep6 ? cancerStep6.agree1 : false,
        taxNo: cancerStep2.personalId,
        agree2: cancerStep6 ? cancerStep6.agree2 : false,
        agree3: cancerStep6 ? cancerStep6.agree3 : false,
        agree4: cancerStep6 ? cancerStep6.agree4 : false
    }

    const dispatch = useDispatch();
    const { control, register, watch, formState: { errors }, handleSubmit, reset } = useForm({ defaultValues });
    const watchAgree1 = watch('agree1', cancerStep6 ? cancerStep6.agree1 : false);

    const onSubmit = (data) => {
        const params = data;
        params.cancerId = cancerId;
        params.language = languageSelected;
        if (cancerStep6) {
            dispatch(putStep6(params));
        }
        else {
            dispatch(postStep6(params));
        }

        dispatch(setCancerStep6(params))
    };

    if (documentLanguage !== languageSelected) {
        sessionStorage.removeItem('cancerPremium')
        sessionStorage.removeItem('cancerStep1')
        sessionStorage.removeItem('cancerStep2')
        sessionStorage.removeItem('cancerStep3')
        sessionStorage.removeItem('cancerStep4')
        sessionStorage.removeItem('cancerStep5')
        sessionStorage.removeItem('cancerStep6')
        dispatch(setStepActive(0))
        dispatch(setAccordionCancerActive(0))
        navigate('/product/cancer')
    }

    const getFormErrorMessage = (name) => {
        return errors[name] && <div><small className="p-error">{errors[name].message}</small></div>
    };

    return (
        <div className='box-product-cancer'>
            <form id='form-campaign' onSubmit={handleSubmit(onSubmit)}>
                <div className="grid">
                    <div className="col-12 md:col-12 lg:col-12">
                        <label className='font-bold text-sm'>{step2.condition.title1}</label>
                        <p>{step2.condition.lable1}</p>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-12 md:col-12 lg:col-12 gap-2">
                        <Controller name="agree1" control={control} render={({ field }) => (
                            <Checkbox onChange={(e) => field.onChange(e.target.checked)} checked={field.value} autoFocus={true}/>
                        )} />
                        <label className="font-bold ml-2">{step2.condition.agree1}</label>
                        <p className="font-bold text-red-600">{step2.condition.lable2}</p>
                    </div>
                </div>
                {(watchAgree1 || (cancerStep6 && cancerStep6.agree1)) &&
                    (<>
                        <div className="grid">
                            <div className="col-12 md:col-12 lg:col-12 flex flex-column gap-2">
                                <label className='font-bold text-sm'>{step2.condition.texNo}</label>
                                <Controller name="taxNo" control={control} render={({ field, fieldState }) => (
                                    <InputText id={field.name} {...field} />
                                )} />
                            </div>
                        </div>
                    </>)
                }
                <div className="grid">
                    <div className="col-12 md:col-12 lg:col-12 flex flex-column gap-2">
                        <label className='font-bold text-sm'>{step2.condition.title2}</label>
                        <div className="privacy-box small" dangerouslySetInnerHTML={{ __html: `${step2.condition.description1}` }}></div>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-12 md:col-12 lg:col-12 gap-2">
                        <Controller name="agree2" control={control} rules={{ required: warning.condition.agree1 }} render={({ field }) => (
                            <Checkbox id={field.name} onChange={(e) => field.onChange(e.target.checked)} checked={field.value} />
                        )} />
                        <label className="font-bold ml-2">{step2.condition.agree2}</label>
                        {getFormErrorMessage('agree2')}
                    </div>
                </div>
                <div className="grid">
                    <div className="col-12 md:col-12 lg:col-12">
                        <label className='font-bold text-sm'>{step2.condition.title3}</label>
                        <p>{step2.condition.lable3}</p>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-12 md:col-12 lg:col-12 flex flex-column gap-2">
                        <div className="privacy-box small" dangerouslySetInnerHTML={{ __html: `${step2.condition.description2}` }}></div>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-12 md:col-12 lg:col-12 gap-2">
                        <Controller name="agree3" control={control} rules={{ required: warning.condition.agree2 }} render={({ field }) => (
                            <Checkbox id={field.name} onChange={(e) => field.onChange(e.target.checked)} checked={field.value} />
                        )} />
                        <label className="font-bold ml-2">{step2.condition.agree3}</label>
                        {getFormErrorMessage('agree3')}
                    </div>
                </div>
                <div className="grid">
                    <div className="col-12 md:col-12 lg:col-12">
                        <label className='font-bold text-sm'>{step2.condition.title4}</label>
                        <p dangerouslySetInnerHTML={{ __html: `${step2.condition.lable4}` }}></p>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-12 md:col-12 lg:col-12 gap-2">
                        <Controller name="agree4" control={control} render={({ field, fieldState }) => (
                            <Checkbox id={field.name} onChange={(e) => field.onChange(e.target.checked)} checked={field.value}></Checkbox>
                        )} />
                        <label className="font-bold ml-2">{step2.condition.agree4}</label>
                    </div>
                </div>
                <div className="text-center mt-3">
                    <Button className='col-12 md:col-3 lg:col-3' type='submit' label={step2.condition.button} severity="danger" />
                </div>
            </form>
        </div>
    )
}
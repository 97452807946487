import { useSelector } from 'react-redux';
import { Accordion, AccordionTab } from 'primereact/accordion';

export default function ProductCancerGuideline() {

    const { cancerText } = useSelector((state) => state.language);
    let { guideline } = cancerText.step1

    return (
        <div className='box-product-cancer'>
            <Accordion className='guideline-cancer'>
                <AccordionTab header={guideline.header}>
                    <h2 className='text-red-600'>{guideline.title}</h2>
                    <ol className='ml-3'>
                        <li>{guideline.text1}</li>
                        <li>{guideline.text2}</li>
                        <li>{guideline.text3}</li>
                        <li>{guideline.text4}</li>
                        <li>{guideline.text5}</li>
                        <li>{guideline.text6}</li>
                        <li>{guideline.text7}</li>
                        <li>{guideline.text8}</li>
                        <li>{guideline.text9}</li>
                        <li>{guideline.text10}</li>
                        <li>{guideline.text11}</li>
                    </ol>
                </AccordionTab>
            </Accordion>
        </div>
    );
}
import { useRef } from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../../Layouts/Header';
import Footer from '../../Layouts/Footer';

export default function PageMain() {

    const bussinesRef = useRef(null)

    return (
        <div>
            <Header bussinesRef={bussinesRef} />
            <div className="mt-7">
                <Outlet />
            </div>
            <Footer bussinesRef={bussinesRef} />
        </div>
    );
}

import axios from 'axios';
import config from '../../configs/config';

const baseUrl = window.location.origin + config.baseUrl;

export const getPrefixesApi = () => {
    var header = {
        method: 'get',
        url: `${baseUrl}/api/MasterData/Prefixes`,
        headers: {
            'Content-Type': 'application/json'
        }
    };
    return axios(header).then((response) => response);
}

export const getDistrictsApi = () => {
    var header = {
        method: 'get',
        url: `${baseUrl}/api/MasterData/Districts`,
        headers: {
            'Content-Type': 'application/json'
        }
    };
    return axios(header).then((response) => response);
}

export const getProvincesApi = () => {
    var header = {
        method: 'get',
        url: `${baseUrl}/api/MasterData/Provinces`,
        headers: {
            'Content-Type': 'application/json'
        }
    };
    return axios(header).then((response) => response);
}

export const getRelationsApi = () => {
    var header = {
        method: 'get',
        url: `${baseUrl}/api/MasterData/Relations`,
        headers: {
            'Content-Type': 'application/json'
        }
    };
    return axios(header).then((response) => response);
}
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
    getPlanCodeApi,
    getPlanCodePricesApi,
    postStep1Api,
    putStep1Api,
    postStep2Api,
    putStep2Api,
    postStep3Api,
    putStep3Api,
    postStep4Api,
    putStep4Api,
    postStep5Api,
    putStep5Api,
    postStep6Api,
    putStep6Api
} from '../services/api/cancer';

export const getPlanCode = createAsyncThunk('getPlanCode', async (_, { fulfillWithValue, rejectWithValue }) => {
    try {
        const { data } = await getPlanCodeApi();
        return fulfillWithValue(data);
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

export const getPlanCodePrices = createAsyncThunk('getPlanCodePrices', async (_, { fulfillWithValue, rejectWithValue }) => {
    try {
        const { data } = await getPlanCodePricesApi();
        return fulfillWithValue(data);
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

export const postStep1 = createAsyncThunk('postStep1', async (request, { fulfillWithValue, rejectWithValue }) => {
    try {
        const { data } = await postStep1Api(request);
        return fulfillWithValue(data);
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

export const putStep1 = createAsyncThunk('putStep1', async (request, { fulfillWithValue, rejectWithValue }) => {
    try {
        const { data } = await putStep1Api(request.cancerId, request);
        return fulfillWithValue(data);
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

export const postStep2 = createAsyncThunk('postStep2', async (request, { fulfillWithValue, rejectWithValue }) => {
    try {
        const { data } = await postStep2Api(request);
        return fulfillWithValue(data);
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

export const putStep2 = createAsyncThunk('putStep2', async (request, { fulfillWithValue, rejectWithValue }) => {
    try {
        const id = request.cancerId;
        const { data } = await putStep2Api(id, request);
        return fulfillWithValue(data);
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

export const postStep3 = createAsyncThunk('postStep3', async (request, { fulfillWithValue, rejectWithValue }) => {
    try {
        const { data } = await postStep3Api(request);
        return fulfillWithValue(data);
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

export const putStep3 = createAsyncThunk('putStep3', async (request, { fulfillWithValue, rejectWithValue }) => {
    try {
        const id = request.cancerId;
        const { data } = await putStep3Api(id, request);
        return fulfillWithValue(data);
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

export const postStep4 = createAsyncThunk('postStep4', async (request, { fulfillWithValue, rejectWithValue }) => {
    try {
        const { data } = await postStep4Api(request);
        return fulfillWithValue(data);
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

export const putStep4 = createAsyncThunk('putStep4', async (request, { fulfillWithValue, rejectWithValue }) => {
    try {
        const id = request.cancerId;
        const { data } = await putStep4Api(id, request);
        return fulfillWithValue(data);
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

export const postStep5 = createAsyncThunk('postStep5', async (request, { fulfillWithValue, rejectWithValue }) => {
    try {
        const { data } = await postStep5Api(request);
        return fulfillWithValue(data);
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

export const putStep5 = createAsyncThunk('putStep5', async (request, { fulfillWithValue, rejectWithValue }) => {
    try {
        const id = request.cancerId;
        const { data } = await putStep5Api(id, request);
        return fulfillWithValue(data);
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

export const postStep6 = createAsyncThunk('postStep6', async (request, { fulfillWithValue, rejectWithValue }) => {
    try {
        const { data } = await postStep6Api(request);
        return fulfillWithValue(data);
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

export const putStep6 = createAsyncThunk('putStep6', async (request, { fulfillWithValue, rejectWithValue }) => {
    try {
        const id = request.cancerId;
        const { data } = await putStep6Api(id, request);
        return fulfillWithValue(data);
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

const cancerSlice = createSlice({
    name: 'cancer',
    initialState: {
        stepCancerActive: 0,
        accordionCancerActive: 0,
        loadingPlanCode: false,
        loadingPlanCodePrice: false,
        loadingStep1: false,
        loadingStep2: false,
        loadingStep3: false,
        loadingStep4: false,
        loadingStep5: false,
        productNameId: sessionStorage.getItem('productNameId') ? parseInt(sessionStorage.getItem('productNameId')) : 0,
        documentLanguage: sessionStorage.getItem('documentLanguage') ? parseInt(sessionStorage.getItem('cancerId')) : '',
        cancerId: sessionStorage.getItem('cancerId') ? parseInt(sessionStorage.getItem('cancerId')) : 0,
        cancerPlanCodeData: sessionStorage.getItem('cancerPlanCodeData') ? JSON.parse(sessionStorage.getItem('cancerPlanCodeData')) : [],
        cancerPlanCodePricesData: sessionStorage.getItem('cancerPlanCodePricesData') ? JSON.parse(sessionStorage.getItem('cancerPlanCodePricesData')) : [],
        cancerPremium: sessionStorage.getItem('cancerPremium') ? JSON.parse(sessionStorage.getItem('cancerPremium')) : 0,
        cancerStep1: sessionStorage.getItem('cancerStep1') ? JSON.parse(sessionStorage.getItem('cancerStep1')) : null,
        cancerStep2: sessionStorage.getItem('cancerStep2') ? JSON.parse(sessionStorage.getItem('cancerStep2')) : null,
        cancerStep3: sessionStorage.getItem('cancerStep3') ? JSON.parse(sessionStorage.getItem('cancerStep3')) : null,
        cancerStep4: sessionStorage.getItem('cancerStep4') ? JSON.parse(sessionStorage.getItem('cancerStep4')) : null,
        cancerStep5: sessionStorage.getItem('cancerStep5') ? JSON.parse(sessionStorage.getItem('cancerStep5')) : null,
        cancerStep6: sessionStorage.getItem('cancerStep6') ? JSON.parse(sessionStorage.getItem('cancerStep6')) : null,
        isDataValid: sessionStorage.getItem('isDataValid') ? JSON.parse(sessionStorage.getItem('isDataValid')) : false
    },
    reducers: {
        setDataValid: (state, action) => {
            state.isDataValid = action.payload
            sessionStorage.setItem('isDataValid', action.payload)
        },
        setDocumentLanguage: (state, action) => {
            state.documentLanguage = action.payload
            sessionStorage.setItem('documentLanguage', action.payload)
        },
        setStepActive: (state, action) => {
            state.stepCancerActive = action.payload
        },
        setAccordionCancerActive: (state, action) => {
            state.accordionCancerActive = action.payload
        },
        setCancerId: (state, action) => {
            state.cancerId = action.payload
            sessionStorage.setItem('cancerId', action.payload)
        },
        setCancerPremium: (state, action) => {
            state.cancerPremium = action.payload
            sessionStorage.setItem('cancerPremium', action.payload)
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPlanCode.fulfilled, (state, action) => {
                const data = action.payload.data
                state.cancerPlanCodeData = data
                state.loadingPlanCode = false
                sessionStorage.setItem('cancerPlanCodeData', JSON.stringify(data))
            })
            .addCase(getPlanCode.pending, (state, action) => {
                state.loadingPlanCode = false
            })
            .addCase(getPlanCode.rejected, (state, action) => {

            })

            .addCase(getPlanCodePrices.fulfilled, (state, action) => {
                const data = action.payload.data
                state.cancerPlanCodePricesData = data
                sessionStorage.setItem('cancerPlanCodePricesData', JSON.stringify(data))
                state.loadingPlanCodePrice = false
            })
            .addCase(getPlanCodePrices.pending, (state, action) => {
                state.loadingPlanCodePrice = true
            })
            .addCase(getPlanCodePrices.rejected, (state, action) => {

            })

            .addCase(postStep1.fulfilled, (state, action) => {
                state.loadingStep1 = false
                const param = action.meta.arg
                const data = action.payload.data
                state.cancerId = data.cancerId
                sessionStorage.setItem('cancerId', data.cancerId)
                state.productNameId = data.productNameId
                sessionStorage.setItem('productNameId', data.productNameId)
                state.cancerStep1 = param
                sessionStorage.setItem('cancerStep1', JSON.stringify(param))
                state.accordionCancerActive = 0
            })
            .addCase(postStep1.pending, (state, action) => {
                state.loadingStep1 = true
            })
            .addCase(postStep1.rejected, (state, action) => {

            })

            .addCase(putStep1.fulfilled, (state, action) => {
                state.loadingStep1 = false
                const param = action.meta.arg
                sessionStorage.setItem('cancerStep1', JSON.stringify(param))
                state.accordionCancerActive = 0
            })
            .addCase(putStep1.pending, (state, action) => {
                state.loadingStep1 = true
            })
            .addCase(putStep1.rejected, (state, action) => {

            })

            .addCase(postStep2.fulfilled, (state, action) => {
                state.loadingStep2 = false
                const param = action.meta.arg
                state.cancerStep2 = param
                sessionStorage.setItem('cancerStep2', JSON.stringify(param))
                state.accordionCancerActive = 1
            })
            .addCase(postStep2.pending, (state, action) => {
                state.loadingStep2 = true
            })
            .addCase(postStep2.rejected, (state, action) => {

            })

            .addCase(putStep2.fulfilled, (state, action) => {
                state.loadingStep2 = false
                const param = action.meta.arg
                state.cancerStep2 = param
                sessionStorage.setItem('cancerStep2', JSON.stringify(param))
                state.accordionCancerActive = 1
            })
            .addCase(putStep2.pending, (state, action) => {
                state.loadingStep2 = true
            })
            .addCase(putStep2.rejected, (state, action) => {

            })

            .addCase(postStep3.fulfilled, (state, action) => {
                state.loadingStep3 = false
                const param = action.meta.arg
                state.cancerStep3 = param
                sessionStorage.setItem('cancerStep3', JSON.stringify(param))
                state.accordionCancerActive = 2
            })
            .addCase(postStep3.pending, (state, action) => {
                state.loadingStep3 = true
            })
            .addCase(postStep3.rejected, (state, action) => {

            })
            .addCase(putStep3.fulfilled, (state, action) => {
                state.loadingStep3 = false
                const param = action.meta.arg
                state.cancerStep3 = param
                sessionStorage.setItem('cancerStep3', JSON.stringify(param))
                state.accordionCancerActive = 2
            })
            .addCase(putStep3.pending, (state, action) => {
                state.loadingStep3 = true
            })
            .addCase(putStep3.rejected, (state, action) => {

            })

            .addCase(postStep4.fulfilled, (state, action) => {
                state.loadingStep4 = false
                const param = action.meta.arg
                state.cancerStep4 = param
                sessionStorage.setItem('cancerStep4', JSON.stringify(param))
                state.accordionCancerActive = 3
            })
            .addCase(postStep4.pending, (state, action) => {
                state.loadingStep4 = true
            })
            .addCase(postStep4.rejected, (state, action) => {

            })
            .addCase(putStep4.fulfilled, (state, action) => {
                state.loadingStep4 = false
                const param = action.meta.arg
                state.cancerStep4 = param
                sessionStorage.setItem('cancerStep4', JSON.stringify(param))
                state.accordionCancerActive = 3
            })
            .addCase(putStep4.pending, (state, action) => {
                state.loadingStep4 = true
            })
            .addCase(putStep4.rejected, (state, action) => {

            })

            .addCase(postStep5.fulfilled, (state, action) => {
                state.loadingStep5 = false
                const param = action.meta.arg
                state.cancerStep5 = param
                sessionStorage.setItem('cancerStep5', JSON.stringify(param))
                state.accordionCancerActive = 4
            })
            .addCase(postStep5.pending, (state, action) => {
                state.loadingStep5 = true
            })
            .addCase(postStep5.rejected, (state, action) => {

            })
            .addCase(putStep5.fulfilled, (state, action) => {
                state.loadingStep5 = false
                const param = action.meta.arg
                state.cancerStep5 = param
                sessionStorage.setItem('cancerStep5', JSON.stringify(param))
                state.accordionCancerActive = 4
            })
            .addCase(putStep5.pending, (state, action) => {
                state.loadingStep5 = true
            })
            .addCase(putStep5.rejected, (state, action) => {

            })

            .addCase(postStep6.fulfilled, (state, action) => {
                state.loadingStep6 = false
                const param = action.meta.arg
                state.cancerStep6 = param
                sessionStorage.setItem('cancerStep6', JSON.stringify(param))
                state.stepCancerActive = 2
            })
            .addCase(postStep6.pending, (state, action) => {
                state.loadingStep6 = true
            })
            .addCase(postStep6.rejected, (state, action) => {

            })
            .addCase(putStep6.fulfilled, (state, action) => {
                state.loadingStep6 = false
                const param = action.meta.arg
                state.cancerStep6 = param
                sessionStorage.setItem('cancerStep6', JSON.stringify(param))
                state.stepCancerActive = 2
            })
            .addCase(putStep6.pending, (state, action) => {
                state.loadingStep6 = true
            })
            .addCase(putStep6.rejected, (state, action) => {

            })
    }
})

export default cancerSlice
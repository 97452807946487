import { Button } from 'primereact/button';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import componentSlice from '../../../../../Redux/componentSlice';
import cancerSlice, { postStep3, putStep3 } from '../../../../../Redux/cancerSlice';
import { useNavigate } from 'react-router-dom';

export default function ProductCancerQuestion() {

    const navigate = useNavigate();

    const { cancerText, languageSelected } = useSelector((state) => state.language);
    let { question } = cancerText.step2
    
    const { cancerId, cancerStep3, documentLanguage } = useSelector((state) => state.cancer)
    const { setCancerStep3, setStepActive, setAccordionCancerActive } = cancerSlice.actions;
    const { showDailogWarningButton } = componentSlice.actions

    const defaultValues = {
        cancerId: 0,
        isAccept: true
    }

    const setVisibleDailog = () => {
        dispatch(showDailogWarningButton({ visible: true, title: question.popup.title2, message: question.popup.description, button: question.popup.button3 }))
    }

    const dispatch = useDispatch();
    const { formState: { errors }, handleSubmit, reset } = useForm({ defaultValues });

    const onSubmit = (data) => {
        const params = data;
        params.cancerId = cancerId;

        if (cancerStep3) {
            dispatch(putStep3(params));
        }
        else {
            dispatch(postStep3(params));
        }
    };

    if (documentLanguage !== languageSelected) {
        sessionStorage.removeItem('cancerPremium')
        sessionStorage.removeItem('cancerStep1')
        sessionStorage.removeItem('cancerStep2')
        sessionStorage.removeItem('cancerStep3')
        sessionStorage.removeItem('cancerStep4')
        sessionStorage.removeItem('cancerStep5')
        sessionStorage.removeItem('cancerStep6')
        dispatch(setStepActive(0))
        dispatch(setAccordionCancerActive(0))
        navigate('/product/cancer')
    }

    return (
        <div className='box-product-cancer'>
            <form id='cancer-question' onSubmit={handleSubmit(onSubmit)}>
                <h2 className='text-red-600'>{question.title1}</h2>
                <h4>{question.question1}</h4>
                <p>{question.answer1}</p><br />
                <h4>{question.question2}</h4>
                <p>{question.answer2}</p><br />
                <h4>{question.question3}</h4>
                <p>{question.answer3}</p><br />
                <h4>{question.lable1} <span className="text-green-600">{question.lable2}</span></h4><br />
                <div className="question grid text-center">
                    <div className="col-12 md:col-6">
                        <Button label={question.button2} type='submit' className='w-full md:w-full' severity="success" outlined autoFocus={true} />
                    </div>
                    <div className="col-12 md:col-6">
                        <Button label={question.button1} severity="danger" className='w-full md:w-full' type='button' outlined onClick={() => setVisibleDailog()} />
                    </div>
                </div>
                <br />
                <h4>{question.lable3}</h4>
            </form>

        </div>
    )
}
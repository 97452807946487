import { createSlice } from '@reduxjs/toolkit'

const homeSlice = createSlice({
    name: 'home',
    initialState: {
    },
    reducers: {
    }
})

export default homeSlice
import { useRef, useEffect } from 'react';
import { Steps } from 'primereact/steps';
import { Toast } from 'primereact/toast';
import { useForm, Controller } from 'react-hook-form';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Panel } from 'primereact/panel';
import { InputText } from 'primereact/inputtext';
import { useSelector, useDispatch } from 'react-redux';
import cancerSlice, { getPlanCode, getPlanCodePrices } from '../../../../Redux/cancerSlice';
import { prefixes, districts, provinces, relations } from '../../../../Redux/masterSlice';
import ProductCancerBanner from '../../../../components/Product/Cancer/Step1/Banner';
import ProductCancerSelection from '../../../../components/Product/Cancer/Step1/Selection';
import ProductCancerProtection from '../../../../components/Product/Cancer/Step1/Protection';
import ProductCancerPaymentMethod from '../../../../components/Product/Cancer/Step1/PaymentMethod';
import ProductCancerGuideline from '../../../../components/Product/Cancer/Step1/Guideline';
import ProductCancerQA from '../../../../components/Product/Cancer/Step1/QA';
import ProductCancerContact from '../../../../components/Product/Cancer/Step1/Contact';
import ProductCancerInformation from '../../../../components/Product/Cancer/Step2/Information';
import ProductCancerQuestion from '../../../../components/Product/Cancer/Step2/Question';
import ProductCancerAddress from '../../../../components/Product/Cancer/Step2/Address';
import ProductCancerHowTo from '../../../../components/Product/Cancer/Step2/HowTo';
import ProductCancerCondition from '../../../../components/Product/Cancer/Step2/Condition';
import ProductCancerPayment from '../../../../components/Product/Cancer/Step3/Payment';
import { useNavigate, useParams } from 'react-router-dom';
import { postPromotionsApplyCodeCostAmount, putPromotionsApplyCodeCostAmount } from '../../../../Redux/promotionsSlice';
import componentSlice from '../../../../Redux/componentSlice';
import promotionsSlice from '../../../../Redux/promotionsSlice';

export default function PageProductCancer() {

    const navigate = useNavigate();

    const { cancerText, languageSelected } = useSelector((state) => state.language);
    let { steps, step1, step2 } = cancerText

    const toast = useRef(null);
    const planRef = useRef(null);
    const paymentRef = useRef(null);
    const dispatch = useDispatch()

    const { mode } = useParams()

    const { stepCancerActive, cancerPlanCodeData, cancerPlanCodePricesData, accordionCancerActive, cancerPremium, cancerPremiumCal, loadingPlanCode, loadingPlanCodePrice, cancerStep5, cancerId, productNameId } = useSelector((state) => state.cancer)
    const { prefixesData, districtsData, provincesData, relationsData, loadingPrefixes, loadingDistricts, loadingProvinces, loadingRelations } = useSelector((state) => state.master)
    const { setStepActive, setAccordionCancerActive, setDocumentLanguage } = cancerSlice.actions
    const { setPromotionCode } = promotionsSlice.actions
    const { promotionCode, codeAvailable, promotionDesc, postPromotionsApplyCodeCostAmonutData } = useSelector((state) => state.promotions)
    const { toggleLoading, showDailogProtection } = componentSlice.actions

    useEffect(() => {
        dispatch(setDocumentLanguage(languageSelected))
        if (mode && mode == 'revise' && cancerStep5) {
            let fullUrl = window.location.href
            let queryIndex = fullUrl.indexOf("?")
            let queryParams = fullUrl.substring(queryIndex, fullUrl.length)
            dispatch(setStepActive(2))
            navigate(`/product/cancer${(queryIndex >= 0 ? queryParams : '')}`);
        }
        if (cancerPlanCodeData.length <= 0 && !loadingPlanCode) dispatch(getPlanCode())
        if (cancerPlanCodePricesData.length <= 0 && !loadingPlanCodePrice) dispatch(getPlanCodePrices())
        if (prefixesData.length <= 0 && !loadingPrefixes) dispatch(prefixes())
        if (districtsData.length <= 0 && !loadingDistricts) dispatch(districts())
        if (provincesData.length <= 0 && !loadingProvinces) dispatch(provinces())
        if (relationsData.length <= 0 && !loadingRelations) dispatch(relations())

        setValue('promotionCode', promotionCode)
    }, [dispatch]);

    const defaultValues = {
        promotionCode: promotionCode ? promotionCode : '',
    }

    const { control, formState: { errors }, handleSubmit, setValue } = useForm({ defaultValues });

    const items = [
        {
            label: steps.item1
        },
        {
            label: steps.item2
        },
        {
            label: steps.item3
        }
    ];

    const changeStep = (index) => {
        if (index < stepCancerActive) {
            dispatch(setStepActive(index))
        }
    }

    const checkPromotionCode = (event) => {
        const promotionCode = event.target.value
        dispatch(setPromotionCode(promotionCode))

        if (promotionCode) {
            const params = {
                promotionId: postPromotionsApplyCodeCostAmonutData ? postPromotionsApplyCodeCostAmonutData.promotionId : 0,
                productNameId: productNameId,
                productId: cancerId,
                code: promotionCode,
                costAmount: cancerPremiumCal ? cancerPremiumCal : cancerPremium
            }

            if (postPromotionsApplyCodeCostAmonutData) {
                dispatch(putPromotionsApplyCodeCostAmount(params));
            } else {
                dispatch(postPromotionsApplyCodeCostAmount(params));
            }
        }
    }

    const labelPromotion = () => {

        const { th, en } = promotionDesc;
        let classLabel = 'text-green-700';
        let msg = step1.promotionCode.message.valid;
        if (codeAvailable && codeAvailable > 0) {
            if (codeAvailable <= 5) {
                classLabel = 'text-orange-500';
                msg = step1.promotionCode.message.codeAmuont1 + codeAvailable + step1.promotionCode.message.codeAmuont2;
            }
        } else {
            classLabel = 'text-red-600';
            msg = languageSelected === 'TH' ? th : en;;
        }
        return <>
            <strong><label className={classLabel}>{msg}</label></strong>
        </>

    }

    const clickDailogProtection = () => {
        dispatch(showDailogProtection({ visible: true }))
    }

    return (

        <div className='box-cancer-container'>
            <ProductCancerBanner />
            <h1 className='mt-2 text-center'>{steps.convering}</h1>
            <Toast ref={toast}></Toast>
            <Steps className='mt-2' model={items} activeIndex={stepCancerActive} onSelect={(e) => changeStep(e.index)} readOnly={false} />
            <div className='mt-2 text-center font-bold text-xl' dangerouslySetInnerHTML={{ __html: `${steps.lable}` }}></div>
            {/* Step 1 */}
            {stepCancerActive === 0 &&
                <div id='cancer-selection'>
                    <ProductCancerSelection planRef={planRef} />
                    <div ref={planRef}>
                        <ProductCancerProtection />
                    </div>
                    <ProductCancerPaymentMethod />
                    <ProductCancerGuideline />
                    <ProductCancerQA />
                    <ProductCancerContact />
                </div>
            }
            {/* Step 1 */}

            {/* Step 2 */}
            {stepCancerActive === 1 &&
                <div className="grid box-cancer box-align-center-mb">

                    {/* For mobile */}
                    <div className="col-12 md:col-3 lg:col-2 hidden-md">
                        <Panel className="selection-dailog-cancer mt-2" header={step1.promotionCode.promotionCodeBox.more} onClick={() => clickDailogProtection()}>
                            <span className="m-0">{step1.promotionCode.promotionCodeBox.lable1}</span> <br />
                            <strong>
                                <span className="m-0">
                                    <label>{cancerPremiumCal ? cancerPremiumCal.toLocaleString() : cancerPremium.toLocaleString()} {step1.promotionCode.promotionCodeBox.lable2}</label>
                                </span>
                            </strong>
                        </Panel>
                        <Panel className="panel-dailog-cancer mt-2">
                            <h2 className='text-red-600'>{step1.promotionCode.title}</h2>
                            <Controller name="promotionCode" control={control} render={({ field, fieldState }) => (
                                <InputText id={field.name} {...field} className="p-inputtext-sm col-12 mb-1" placeholder='Promotion code' onBlur={(e) => checkPromotionCode(e)} />
                            )} />
                            {labelPromotion()}
                        </Panel>
                    </div>
                    {/* For mobile */}

                    <div className="col-12 md:col-9 lg:col-10">
                        <Accordion activeIndex={accordionCancerActive} onTabChange={(e) => dispatch(setAccordionCancerActive(e.index))}>
                            <AccordionTab header={step2.information.header} >
                                <ProductCancerInformation />
                            </AccordionTab>
                            <AccordionTab header={step2.question.header} disabled={accordionCancerActive < 1}>
                                <ProductCancerQuestion />
                            </AccordionTab>
                            <AccordionTab header={step2.address.header} disabled={accordionCancerActive < 2}>
                                <ProductCancerAddress />
                            </AccordionTab>
                            <AccordionTab header={step2.howTo.header} disabled={accordionCancerActive < 3}>
                                <ProductCancerHowTo />
                            </AccordionTab>
                            <AccordionTab header={step2.condition.header} disabled={accordionCancerActive < 4}>
                                <ProductCancerCondition />
                            </AccordionTab>
                        </Accordion>
                    </div>

                    {/* For desktop */}
                    <div className="col-12 md:col-3 lg:col-2 hidden-sm">
                        <Panel className="selection-dailog-cancer mt-2 md:mt-0" header={step1.promotionCode.promotionCodeBox.more} onClick={() => clickDailogProtection()} >
                            <span className="m-0">{step1.promotionCode.promotionCodeBox.lable1}</span> <br />
                            <strong>
                                <span className="m-0">
                                    <label>{cancerPremiumCal ? cancerPremiumCal.toLocaleString() : cancerPremium.toLocaleString()} {step1.promotionCode.promotionCodeBox.lable2}</label>
                                </span>
                            </strong>
                        </Panel>
                        <Panel className="panel-dailog-cancer mt-2">
                            <h2 className='text-red-600'>{step1.promotionCode.title}</h2>
                            <Controller name="promotionCode" control={control} render={({ field, fieldState }) => (
                                <InputText id={field.name} {...field} className="p-inputtext-sm col-12 mb-1" placeholder='Promotion code' onBlur={(e) => checkPromotionCode(e)} />
                            )} />
                            {labelPromotion()}
                        </Panel>
                    </div>
                    {/* For desktop */}

                </div>
            }
            {/* Step 3 */}
            {stepCancerActive === 2 &&
                <div id='cancer-payment' ref={paymentRef}>
                    <ProductCancerPayment paymentRef={paymentRef} />
                </div>
            }

        </div>

    );
}
import { createSlice } from '@reduxjs/toolkit';
import th from '../configs/language/th';
import en from '../configs/language/en';
import cancerTh from '../configs/language/cancer/th';
import cancerEn from '../configs/language/cancer/en';

const languageSlice = createSlice({
    name: 'language',
    initialState: {
        languageSelected: sessionStorage.getItem('language') ? sessionStorage.getItem('language') : 'TH',
        cancerText: (sessionStorage.getItem('language') && sessionStorage.getItem('language') === 'EN') ? cancerEn : cancerTh,
        languageEng: en,
        languageThai: th
    },
    reducers: {
        setLanguege: (state, action) => {
            state.languageSelected = action.payload
            sessionStorage.setItem('language', action.payload)
            state.cancerText = action.payload === 'TH' ? cancerTh : cancerEn
        }
    }
})

export default languageSlice
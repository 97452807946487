import { Image } from 'primereact/image';
import { Panel } from 'primereact/panel';
import { useSelector } from 'react-redux';
import bannerDesktopTH from '../../../../../Assets/images/cancer/banner/banner-cancer-desktop-th.webp';
import bannerDesktopEN from '../../../../../Assets/images/cancer/banner/banner-cancer-desktop-en.webp';
import bannerMobileTH from '../../../../../Assets/images/cancer/banner/banner-cancer-mobile-th.webp';
import bannerMobileEN from '../../../../../Assets/images/cancer/banner/banner-cancer-mobile-en.webp';

export default function ProductCancerBanner() {

    const { cancerText, languageSelected } = useSelector((state) => state.language);
    let { banners } = cancerText

    return (
        <div className='box-product-cancer'>
            <>
                <Image className='hidden-sm' src={languageSelected === 'TH' ? bannerDesktopTH : bannerDesktopEN} alt="Image" width="100%" />
                <Image className='hidden-md' src={languageSelected === 'TH' ? bannerMobileTH : bannerMobileEN} alt="Image" width="100%" />
                <div className='text-center text-xs md:text-2xl mt-2' dangerouslySetInnerHTML={{ __html: `${banners.topic}` }}>
                </div>
                <div className="grid panel-banner-cancer text-center flex flex-row mt-2 p-2">
                    <div className="col-6 md:col-4 lg:col-4 p-1">
                        <Panel className='panel-box-cancer'>
                           <label className='text-white font-bold text-base md:text-lg' dangerouslySetInnerHTML={{ __html: `${banners.highlight.item1}` }}></label>
                        </Panel>
                    </div>
                    <div className="col-6 md:col-4 lg:col-4 p-1">
                        <Panel className='panel-box-cancer' >
                           <label className='text-white font-bold text-base md:text-lg' dangerouslySetInnerHTML={{ __html: `${banners.highlight.item2}` }}></label>
                        </Panel>
                    </div>
                    <div className="col-6 md:col-4 lg:col-4 p-1">
                        <Panel className='panel-box-cancer'>
                           <label className='text-white font-bold text-base md:text-lg'>{banners.highlight.item3}</label>
                        </Panel>
                    </div>
                    <div className="col-6 md:col-4 lg:col-4 p-1">
                        <Panel className='panel-box-cancer'>
                           <label className='text-white font-bold text-base md:text-lg'>{banners.highlight.item4}</label>
                        </Panel>
                    </div>
                    <div className="col-6 md:col-4 lg:col-4 p-1">
                        <Panel className='panel-box-cancer' >
                            <h4></h4>
                           <label className='text-white font-bold text-base md:text-lg'>{banners.highlight.item5}</label>
                        </Panel>
                    </div>
                    <div className="col-6 md:col-4 lg:col-4 p-1">
                        <Panel className='panel-box-cancer' >
                           <label className='text-white font-bold text-base md:text-lg'>{banners.highlight.item6}</label>
                        </Panel>
                    </div>
                </div>
            </>
        </div>
    );
}
import { configureStore } from '@reduxjs/toolkit'
import homeSlice from './homeSlice'
import languageSlice from './languageSlice'
import cancerSlice from './cancerSlice'
import masterSlice from './masterSlice'
import componentSlice from './componentSlice'
import promotionsSlice from './promotionsSlice'

const store = configureStore({
    reducer: {
        home: homeSlice.reducer,
        component: componentSlice.reducer,
        language: languageSlice.reducer,
        cancer: cancerSlice.reducer,
        master: masterSlice.reducer,
        promotions: promotionsSlice.reducer
    }
})

export default store

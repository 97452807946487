import { Dialog } from "primereact/dialog";
import { useDispatch, useSelector } from 'react-redux';
import componentSlice from "../../../Redux/componentSlice";

export default function DailogWarning() {

    const dispatch = useDispatch()
    const { dailogWarning } = useSelector((state) => state.component)
    const { showDailogWarning } = componentSlice.actions;

    return (
        <Dialog visible={dailogWarning.visible} style={{ width: '50vw' }} modal className="p-fluid dialog-cancer-question" onHide={() => dispatch(showDailogWarning({ visble: false }))}>
            <div className="grid">
                <div className="col-12">
                    <i className="pi pi-bell" style={{ fontSize: '3rem', color: 'red' }}></i>
                </div>
            </div>
            <div className="grid">
                <div className="col-12 mt-2">
                    <h2>{dailogWarning.title}</h2>
                </div>
            </div>
            <div className="grid">
                <div className="col-12 mt-2">
                    <p className="m-0">{dailogWarning.message}</p>
                </div>
            </div>
        </Dialog >
    );
}
import { Helmet } from "react-helmet"
import { useSelector } from "react-redux"

export default function HtmlHeader() {

    const { languageSelected } = useSelector((state) => state.language)
    return (
        <>
            <Helmet htmlAttributes={{ lang: languageSelected.toLowerCase() }} >
                {/* OneTrust Cookies Consent Notice start for tuneprotect.co.th */}
                <script type="text/javascript" src="https://cdn-apac.onetrust.com/consent/e1a3cf11-6147-44de-b09e-241fa390bac3/OtAutoBlock.js"></script>
                <script src="https://cdn-apac.onetrust.com/scripttemplates/otSDKStub.js" data-document-language="true" type="text/javascript" charset="UTF-8" data-domain-script="e1a3cf11-6147-44de-b09e-241fa390bac3"></script>
                {/* OneTrust Cookies Consent Notice start for tuneprotect.co.th */}


                {/* <!-- Google Tag Manager --> */}
                <script>
                    {`
                    (function (w, d, s, l, i) {
                      w[l] = w[l] || []; w[l].push({
                        'gtm.start':
                          new Date().getTime(), event: 'gtm.js'
                      });
                      var f = d.getElementsByTagName(s)[0],
                          j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
                      j.async = true; 
                      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
                      f.parentNode.insertBefore(j, f);
                    })(window, document, 'script', 'dataLayer', '${process.env.REACT_APP_GTAG_CODE}');
                    `}
                </script>
                {/* <!-- End Google Tag Manager --> */}

            </Helmet>

            {/* <!-- Google Tag Manager (noscript) --> */}
            <noscript>
                <iframe
                    src={`https://www.googletagmanager.com/ns.html?id=${process.env.REACT_APP_GTAG_CODE}`}
                    height="0"
                    width="0"
                    style={{ display: 'none', visibility: 'hidden' }}>
                </iframe>
            </noscript>
            {/* <!-- Google Tag Manager (noscript) --> */}
        </>
    )
}

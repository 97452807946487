import { Dialog } from "primereact/dialog";
import { useDispatch, useSelector } from 'react-redux';
import componentSlice from "../../../Redux/componentSlice";
import ProductCancerProtection from '../../../components/Product/Cancer/Step1/Protection';

export default function DailogProtection() {

    const dispatch = useDispatch()
    const { dailogProtect } = useSelector((state) => state.component)
    const { showDailogProtection } = componentSlice.actions;

    return (
        <Dialog visible={dailogProtect.visible} modal className="p-fluid table-cancer-protection p-2" draggable onHide={() => dispatch(showDailogProtection({ visble: false }))}>
            <ProductCancerProtection />
        </Dialog >
    );
}
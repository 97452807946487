import { useState } from "react";
import { Panel } from 'primereact/panel';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import { useForm, Controller } from 'react-hook-form';
import { InputTextarea } from 'primereact/inputtextarea';
import { classNames } from 'primereact/utils';
import { useSelector } from 'react-redux';

export default function ProductCancerContact() {

    const { cancerText } = useSelector((state) => state.language);
    let { contectUs } = cancerText.step1

    const [selectedTime, setSelectedTime] = useState(null);
    const cities = [
        { name: '09.00 - 12.00 น.', code: '1' },
        { name: '12.00 - 15.00 น.', code: '2' },
        { name: '15.00 - 19.00 น.', code: '3' }
    ];

    const defaultValues = {
        cancerId: ''
    }

    const [checked, setChecked] = useState(false);
    const { control, formState: { errors }, handleSubmit, setValue } = useForm({ defaultValues });

    const getFormErrorMessage = (name) => {
        return errors[name] && <div><small className="p-error">{errors[name].message}</small></div>
    };

    return (
        <div className='box-product-cancer'>
            <Panel className='panel-cancer mt-3'>
                <h2 className='text-red-600'>{contectUs.title}</h2>
                <div className="grid">
                    <div className="col-12 md:col-6 lg:col-6 flex flex-column gap-2">
                        <label className='font-bold text-sm'>{contectUs.name}</label>
                        <InputText id="username" className="p-inputtext-sm" aria-describedby="username-help" />
                    </div>
                    <div className="col-12 md:col-6 lg:col-6 flex flex-column gap-2">
                        <label className='font-bold text-sm'>{contectUs.phone}</label>
                        <InputText id="username" className="p-inputtext-sm" aria-describedby="username-help" />
                    </div>
                </div>
                <div className="grid">
                    <div className="col-12 md:col-6 lg:col-6 flex flex-column gap-2">
                        <label className='font-bold text-sm'>{contectUs.email}</label>
                        <InputText id="username" className="p-inputtext-sm" aria-describedby="username-help" />
                    </div>
                    <div className="col-12 md:col-6 lg:col-6 flex flex-column gap-2">
                        <label className='font-bold text-sm'>{contectUs.contectTime}</label>
                        <Dropdown value={selectedTime} onChange={(e) => setSelectedTime(e.value)} options={cities} optionLabel="name"
                            placeholder={contectUs.placeholderContectTime} className="w-full" />
                    </div>
                </div>
                <div className="grid">
                    <div className="col-12 md:col-12 lg:col-12 flex flex-column gap-2">
                        <label className='font-bold text-sm'>{contectUs.address}</label>
                        <span className="p-float-label">
                            <Controller name="address" control={control} rules={{ required: 'Address is required.' }} render={({ field, fieldState }) => (
                                <InputTextarea id={field.address} {...field} rows={4} className={classNames({ 'p-invalid': fieldState.invalid })} />
                            )} />
                            {getFormErrorMessage('address')}
                        </span>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-12 md:col-12 lg:col-12 gap-2">
                        <Checkbox onChange={e => setChecked(e.checked)} checked={checked}></Checkbox>
                        <label className="font-bold ml-2" dangerouslySetInnerHTML={{ __html: `${contectUs.agreement}` }}></label>
                    </div>
                </div>
                <div className="text-center mt-3">
                    <Button className='col-12 md:col-2 lg:col-2' label={contectUs.button} severity="danger" />
                </div>
            </Panel>
        </div>
    );
}
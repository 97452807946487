import { Dialog } from "primereact/dialog";
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import componentSlice from "../../../Redux/componentSlice";

export default function DailogWarningButton() {
    
    const dispatch = useDispatch()
    const { dailogWarningButton } = useSelector((state) => state.component)
    const { showDailogWarningButton } = componentSlice.actions;

    return (
        <Dialog  visible={dailogWarningButton.visible} style={{ width: '50vw' }} breakpoints={{ '960px': '75vw', '641px': '100vw' }} modal className="p-fluid dialog-cancer-question" onHide={() => dispatch(showDailogWarningButton({visible: false}))}>
            <div className="grid">
                <div className="col-12">
                    <i className="pi pi-bell" style={{ fontSize: '3rem', color: 'red' }}></i>
                </div>
            </div>
            <div className="grid">
                <div className="col-12 mt-2">
                    <h2>{dailogWarningButton.title}</h2>
                </div>
            </div>
            <div className="grid">
                <div className="col-12 mt-2">
                    <p className="m-0">{dailogWarningButton.message}</p>
                </div>
            </div>
            <div className="grid">
                <div className="col-12 mt-2">
                    <Button className='col-12 md:col-3 lg:col-3' label={dailogWarningButton.button} type="button" severity="danger" />
                </div>
            </div>
        </Dialog>
    );
}

import { useRef } from "react";
import ImageGallery from 'react-image-gallery';
import { useSelector } from 'react-redux';
import config from '../../../configs/config';

export default function HomeSlide() {

    const _imageGallery = useRef(null);

    const { languageSelected, languageEng, languageThai } = useSelector((state) => state.language)
    const images = languageSelected === 'TH' ? languageThai.banners : languageEng.banners;

    const slideClick = (event) => {
        const currentIndex = _imageGallery.current.state.currentIndex;
        const currentItem = images[currentIndex];
        window.open(`${config.webUrlRedirect}/${currentItem.url}`, '_blank');
        // TO DO Redirect to another page
    }

    return (
        <>
            {/* TO DO Slide image */}
            <div className="slide-banner">
                <ImageGallery ref={_imageGallery} items={images} autoPlay={true} showPlayButton={true} showThumbnails={false} showFullscreenButton={true} showNav={true} onClick={slideClick} />
            </div>
        </>
    );
}
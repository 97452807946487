import { useSelector } from 'react-redux';
import { Accordion, AccordionTab } from 'primereact/accordion';

export default function ProductCancerQA() {

    const { cancerText } = useSelector((state) => state.language);
    let { question } = cancerText.step1

    return (
        <div className='box-product-cancer'>
            <Accordion className='accordion-cancer'>
                <AccordionTab header={question.header}>
                    <Accordion className='sub-qa-cancer'>
                        <AccordionTab header={question.answer1.title}>
                            <ul className='ml-3'>
                                <li>{question.answer1.text1}</li>
                                <li>{question.answer1.text2}</li>
                                <li>{question.answer1.text3}</li>
                            </ul>
                        </AccordionTab>
                        <AccordionTab header={question.answer2.title} className='mb-3 md:mb-0'>
                            <ul className='ml-3'>
                                <li>{question.answer2.text1}</li>
                                <li>{question.answer2.text2}</li>
                                <li>{question.answer2.text3}</li>
                            </ul>
                        </AccordionTab>
                        <AccordionTab header={question.answer3.title}>
                            <ul className='ml-3'>
                                <li>{question.answer3.text1}</li>
                            </ul>
                        </AccordionTab>
                        <AccordionTab header={question.answer4.title}>
                            <ul className='ml-3'>
                                <li>{question.answer4.text1}</li>
                                <li>{question.answer4.text2}</li>
                                <li>{question.answer4.text3}</li>
                                <li>{question.answer4.text4}</li>
                                <li dangerouslySetInnerHTML={{ __html: `${question.answer4.text5}` }}></li>
                            </ul>
                        </AccordionTab>
                    </Accordion>
                </AccordionTab>
            </Accordion>
        </div>
    );
}
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { postPromotionsApplyCodeApi, postPromotionsApplyCodeCostAmountApi, putPromotionsApplyCodeCostAmountApi } from '../services/api/promotions';

export const promotionsApplyCode = createAsyncThunk('promotionsApplyCode', async (request, { fulfillWithValue, rejectWithValue }) => {
    try {
        const { data } = await postPromotionsApplyCodeApi(request);
        return fulfillWithValue(data);
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

export const postPromotionsApplyCodeCostAmount = createAsyncThunk('postPromotionsApplyCodeCostAmount', async (request, { fulfillWithValue, rejectWithValue }) => {
    try {
        const { data } = await postPromotionsApplyCodeCostAmountApi(request);
        return fulfillWithValue(data);
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

export const putPromotionsApplyCodeCostAmount = createAsyncThunk('putPromotionsApplyCodeCostAmount', async (request, { fulfillWithValue, rejectWithValue }) => {
    try {
        const id = request.promotionId;
        const { data } = await putPromotionsApplyCodeCostAmountApi(id, request);
        return fulfillWithValue(data);
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

const promotionsSlice = createSlice({
    name: 'promotions',
    initialState: {
        promotionCode: '',
        codeAvailable: sessionStorage.getItem('codeAvailable') ? JSON.parse(sessionStorage.getItem('codeAvailable')) : 0,
        promotionDesc: sessionStorage.getItem('promotionDesc') ? JSON.parse(sessionStorage.getItem('promotionDesc')) : { th: '', en: '' }
    },
    reducers: {
        setPromotionCode: (state, action) => {
            state.promotionCode = action.payload
        },
        setCodeAvailable: (state, action) => {
            state.codeAvailable = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(promotionsApplyCode.fulfilled, (state, action) => {
                const data = action.payload.data;
                state.codeAvailable = data.codeAvailable
                sessionStorage.setItem('codeAvailable', JSON.stringify(data.codeAvailable))
                const promotionDesc = { en: data.message, th: data.message_th }
                state.promotionDesc = promotionDesc
                sessionStorage.setItem('promotionDesc', JSON.stringify(promotionDesc))
            })
            .addCase(promotionsApplyCode.rejected, (state, action) => {
                const data = action.payload.data;
                state.codeAvailable = (data && data.codeAvailable) ? data.codeAvailable : 0
                sessionStorage.setItem('codeAvailable', JSON.stringify(state.codeAvailable))
                const promotionDesc = { en: (data && data.message) ? data.message : 'The code does not qualify for the promotion as specified.', 
                                        th: (data && data.message_th) ? data.message_th : 'โค้ดของคุณไม่เข้าเงื่อนไขโปรโมชั่นตามที่กำหนด' }
                state.promotionDesc = promotionDesc
                sessionStorage.setItem('promotionDesc', JSON.stringify(promotionDesc))
            })
            .addCase(postPromotionsApplyCodeCostAmount.fulfilled, (state, action) => {
                const data = action.payload.data;
                state.codeAvailable = data.codeAvailable
                sessionStorage.setItem('codeAvailable', JSON.stringify(data.codeAvailable))

                const promotionDesc = { en: data.message, th: data.message_th }
                state.promotionDesc = promotionDesc
                sessionStorage.setItem('promotionDesc', JSON.stringify(promotionDesc))

            })
            .addCase(postPromotionsApplyCodeCostAmount.rejected, (state, action) => {
                const data = action.payload.data;
                state.codeAvailable = (data && data.codeAvailable) ? data.codeAvailable : 0
                sessionStorage.setItem('codeAvailable', JSON.stringify(state.codeAvailable))
                const promotionDesc = { en: (data && data.message) ? data.message : 'The code does not qualify for the promotion as specified.', 
                                        th: (data && data.message_th) ? data.message_th : 'โค้ดของคุณไม่เข้าเงื่อนไขโปรโมชั่นตามที่กำหนด' }
                state.promotionDesc = promotionDesc
                sessionStorage.setItem('promotionDesc', JSON.stringify(promotionDesc))
            })
            .addCase(putPromotionsApplyCodeCostAmount.fulfilled, (state, action) => {
                const data = action.payload.data;
                state.codeAvailable = data.codeAvailable
                sessionStorage.setItem('codeAvailable', JSON.stringify(data.codeAvailable))

                const promotionDesc = { en: data.message, th: data.message_th }
                state.promotionDesc = promotionDesc
                sessionStorage.setItem('promotionDesc', JSON.stringify(promotionDesc))
            })
            .addCase(putPromotionsApplyCodeCostAmount.rejected, (state, action) => {
                const data = action.payload.data;
                state.codeAvailable = data.codeAvailable
                sessionStorage.setItem('codeAvailable', JSON.stringify(data.codeAvailable))
                const promotionDesc = { en: data.message, th: data.message_th }
                state.promotionDesc = promotionDesc
                sessionStorage.setItem('promotionDesc', JSON.stringify(promotionDesc))
            })
    }
})

export default promotionsSlice

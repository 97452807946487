import { Image } from 'primereact/image';
import { Button } from 'primereact/button';
import { useDispatch, useSelector } from 'react-redux';
import imgThankyou from '../../../../src/Assets/images/cancer/thankyou/thankyou.jpg';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { pushToDataLayer } from '../../../utilities/gtm'

export default function PageThankyou() {

    const { invoiceNo } = useParams();

    const dispatch = useDispatch();

    const { cancerStep1, cancerPremium } = useSelector((state) => state.cancer);

    const redirect = () => {
        window.location.href = '/product/cancer';
    };

    useEffect(() => {

        pushToDataLayer('purchase',
            {
                "currency": "THB",
                "transaction_id": invoiceNo,
                "value": cancerPremium,
                "items": [{
                    "item_id": `e-cencer-${cancerStep1.planCode}`,
                    "item_name": `e-cencer-${cancerStep1.planCode}`,
                    "price": cancerPremium,
                    "item_brand": 'e-cancer',
                    "item_category": 'Health insurance'
                }]
            })
        sessionStorage.clear()
    })

    const { cancerText, languageSelected } = useSelector((state) => state.language);
    let { thankyou } = cancerText

    return (
        <div className='box-container'>
            <div className="wrapper">
                <div className="inner-wrapper">
                    <div className="grid">
                        <div className="col-12">
                            <Image src={imgThankyou} alt="Image" width="120" />
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-12">
                            <h1 className='text-red-600'>{thankyou.header}</h1>
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-12">
                            <p>{thankyou.lable1}</p>
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-12">
                            <p>{thankyou.lable2}<br />
                                <strong>{thankyou.invoice} <span className='text-red-600'>{invoiceNo}</span></strong></p>
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-12">
                            <Button className='col-12 md:col-4 lg:col-3 btn-danger' onClick={redirect} label={thankyou.button} severity="danger" rounded />
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}
import { Button } from 'primereact/button'
import { Card } from 'primereact/card'
import { useSelector } from 'react-redux'
import { chunkArrayInGroups } from '../../../services/common'

export default function HomePromotion() {

    const { languageSelected, languageEng, languageThai } = useSelector((state) => state.language)
    const { topic, all, content } = languageSelected === 'TH' ? languageThai.promotions : languageEng.promotions

    const displayItem = () => {
        const item = chunkArrayInGroups(content, 2)

        return item.map((e, i) =>
            <div className="grid" key={i}>
                {e.map((x, j) =>
                    <div className="col-12 md:col-6 lg:col-6" key={j}>
                        <Card>
                            <div className="p-card-content" >
                                <img src={x.banner} alt="" className='w-full' />
                            </div>
                            <div className="p-card-footer p-0">
                                <div className="border-bottom-1 border-red-300 border-noround">
                                    <h5 className='p-2 m-0 promotion-desc' onClick={() => { window.location.href = `${x.promotionLink}` }}>{x.description}</h5>
                                </div>
                                <div className="grid mt-0">
                                    <div className="col-6 text-center m-0 border-right-1 border-red-300 text-red p-0">
                                        <Button label={x.buttonDetail} severity="danger" text className="w-full p-1" onClick={() => { window.location.href = `${x.promotionLink}` }} />
                                    </div>
                                    <div className="col-6 text-center m-0 text-red p-0">
                                        <Button label={x.buttonBuy} severity="danger" text className="w-full p-1" onClick={() => { window.location.href = `${x.link}` }} />
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>
                )}
            </div>
        )
    }

    return (
        <>
            {/* TO DO Promotion */}
            <div className="box-promotion">
                <h3 className='header-box mb-3'>{topic}</h3>

                {displayItem()}

                <h5 className="text-center mt-1">
                    <a href="https://www.tuneprotect.co.th/th/promotion" target='_blank' className='text-900 no-underline'>
                        {all}
                        <i className="pi pi-arrow-right pl-2 text-sm"></i>
                    </a>
                </h5>

            </div>
        </>
    )
}
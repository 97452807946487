import moment from 'moment';
import { Panel } from 'primereact/panel';
import { Button } from 'primereact/button';
import { useSelector, useDispatch } from 'react-redux';
import cancerSlice from '../../../../../Redux/cancerSlice';
import { postPaymentsGetnerateLinkApi } from '../../../../../services/api/payment';
import { useEffect, useState } from 'react';
import componentSlice from '../../../../../Redux/componentSlice';
import { useNavigate } from 'react-router-dom';
import { pushToDataLayer } from '../../../../../utilities/gtm'

export default function ProductCancerPayment(props) {

    const navigate = useNavigate();

    const { cancerText, languageSelected } = useSelector((state) => state.language);
    let { step1, step3 } = cancerText

    const dispatch = useDispatch();
    const [isSubmit, setIsSubmit] = useState(false);
    const { relationsData } = useSelector((state) => state.master);
    const { cancerId, productNameId, documentLanguage, cancerStep1, cancerStep2, cancerStep4, cancerStep5, cancerStep6, cancerPremium, isDataValid } = useSelector((state) => state.cancer);
    const { setStepActive, setAccordionCancerActive } = cancerSlice.actions
    const { showDailogWarning, setDailogWarningMsg } = componentSlice.actions
    const { promotionDesc, codeAvailable } = useSelector((state) => state.promotions)

    useEffect(() => {
        props.paymentRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
        pushToDataLayer('begin_checkout',
            {
                "currency": "THB",
                "value": cancerPremium,
                "items": [{
                    "item_id": `e-cencer-${cancerStep1.planCode}`,
                    "item_name": `e-cencer-${cancerStep1.planCode}`,
                    "price": cancerPremium,
                    "item_brand": 'e-cancer',
                    "item_category": 'Health insurance'
                }]
            })
    })

    const prefixesData = [
        {
            "titleNameId": 1,
            "code": "042",
            "desc": "นาย",
            "language": "TH"
        },
        {
            "titleNameId": 2,
            "code": "184",
            "desc": "เด็กชาย",
            "language": "TH"
        },
        {
            "titleNameId": 3,
            "code": "001",
            "desc": "MR.",
            "language": "EN"
        },
        {
            "titleNameId": 4,
            "code": "041",
            "desc": "นางสาว",
            "language": "TH"
        },
        {
            "titleNameId": 5,
            "code": "040",
            "desc": "นาง",
            "language": "TH"
        },
        {
            "titleNameId": 6,
            "code": "185",
            "desc": "เด็กหญิง",
            "language": "TH"
        },
        {
            "titleNameId": 7,
            "code": "280",
            "desc": "Miss",
            "language": "EN"
        },
        {
            "titleNameId": 8,
            "code": "002",
            "desc": "MRS.",
            "language": "EN"
        },
        {
            "titleNameId": 9,
            "code": "003",
            "desc": "MS.",
            "language": "EN"
        }
    ]

    const dropdownPrefixes = prefixesData.filter(e => e.language === languageSelected);

    if (documentLanguage !== languageSelected) {
        sessionStorage.removeItem('cancerPremium')
        sessionStorage.removeItem('cancerStep1')
        sessionStorage.removeItem('cancerStep2')
        sessionStorage.removeItem('cancerStep3')
        sessionStorage.removeItem('cancerStep4')
        sessionStorage.removeItem('cancerStep5')
        sessionStorage.removeItem('cancerStep6')
        dispatch(setStepActive(0))
        dispatch(setAccordionCancerActive(0))
        navigate('/product/cancer')
    }

    const calculateAge = (dob) => {
        var effective = moment().add(1, 'd');
        var birthDate = moment(dob);

        var age = effective.year() - birthDate.year();
        if (birthDate > moment(effective).add(-age, 'y')) age--;
        return age;
    }

    const issuePolicy = () => {
        if (isDataValid) {
            setIsSubmit(true);

            let fullUrl = window.location.href
            let queryIndex = fullUrl.indexOf("?")
            let baseUrl = fullUrl.substring(0, queryIndex)
            let queryParams = fullUrl.substring(queryIndex, fullUrl.length)

            const params = {
                productNameId: productNameId,
                productId: cancerId,
                productUrl: queryIndex >= 0 ? baseUrl : fullUrl,
                queryParams: queryIndex >= 0 ? queryParams : '',
                costAmount: cancerPremium
            }

            postPaymentsGetnerateLinkApi(params)
                .then((response) => {
                    const data = response.data;
                    if (data.status) {
                        window.location.replace(data.redirectUrl);
                    }
                }).catch((errors => {
                    console.log(errors);
                }));
        } else {
            dispatch(setDailogWarningMsg('invalid data'))
            dispatch(showDailogWarning(true))
        }
    }

    const editInformation = () => {
        dispatch(setStepActive(1));
        dispatch(setAccordionCancerActive(0));
    }

    const benefitPerson = {
        name: cancerStep5.relationCode === "" ? cancerStep5.person : (cancerStep5.person) + ` (${relationsData.find(e => e.code === cancerStep5.relationCode).titleTh})`
    }

    const displayData = {
        title: dropdownPrefixes.find(s => s.titleNameId == cancerStep2.titleNameId) ? dropdownPrefixes.find(s => s.titleNameId == cancerStep2.titleNameId).desc : '',
        gender: cancerStep2.titleNameId === 1 ? step3.male : step3.female,
        birthDate: cancerStep2.birthDate ? moment(cancerStep2.birthDate).format("DD/MM/YYYY") : '',
        age: calculateAge(cancerStep2.birthDate),
        benefit: cancerStep5.benefitType === "H" ? step3.heir : benefitPerson.name,
        deduction: cancerStep6.agree1 ? step3.yes : step3.no,
        sendType: cancerStep5.docReceive === "E" ? step3.sendEmail : step3.post,
    }

    const labelPromotion = () => {

        const { th, en } = promotionDesc;
        let classLabel = 'text-green-700';
        let msg = step1.promotionCode.message.valid;
        if (codeAvailable && codeAvailable > 0) {
            if (codeAvailable <= 5) {
                classLabel = 'text-orange-500';
                msg = step1.promotionCode.message.codeAmuont1 + codeAvailable + step1.promotionCode.message.codeAmuont2;
            }
        } else {
            classLabel = 'text-red-600';
            msg = languageSelected === 'TH' ? th : en;;
        }
        return <>
            <strong><label className={classLabel}>{msg}</label></strong>
        </>

    }

    return (
        <div className='box-product-cancer'>
            <Panel className="mt-3 panel-payment-cancer text-xl" header={step3.header}>
                <h2 className='text-red-600 text-xl mb-2'>{step3.title1}</h2>
                <div className="grid">
                    <div className="col-12 md:col-6 lg:col-6">
                        <label className='text-base'>{step3.plan} <strong>{step3.productPlan} {cancerStep1.planCode.substring(3, 4)}</strong></label>
                    </div>
                    <div className="col-12 md:col-6 lg:col-6 ">
                        <label className='text-base'>{step3.price} <strong>{cancerPremium.toLocaleString()} {step3.unit}</strong></label>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-12 md:col-6 lg:col-6">
                        <label className='text-base'>{step3.covered} <strong>{step3.cancer}</strong></label>
                    </div>
                </div>
                <h2 className='text-red-600 text-xl mb-2'>{step3.title2}</h2>
                <div className="grid">
                    <div className="col-12 md:col-6 lg:col-6">
                        <label className='text-base'>{step3.name}<strong>{displayData.title} {cancerStep2.firstName} {cancerStep2.middleName} {cancerStep2.lastName}</strong></label>
                    </div>
                    <div className="col-12 md:col-6 lg:col-6">
                        <label className='text-base'>{step3.gender}<strong>{displayData.gender}</strong></label>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-12 md:col-6 lg:col-6">
                        <label className='text-base'>{step3.indentify}<strong>{cancerStep2.personalId}</strong></label>
                    </div>
                    <div className="col-12 md:col-6 lg:col-6">
                        <label className='text-base'>{step3.birthDate}<strong>{displayData.birthDate} ({displayData.age} {step3.year})</strong></label>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-12 md:col-6 lg:col-6">
                        <label className='text-base'>{step3.phone}<strong>{cancerStep2.mobile}</strong></label>
                    </div>
                    <div className="col-12 md:col-6 lg:col-6">
                        <label className='text-base'>{step3.email}<strong>{cancerStep2.email}</strong></label>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-12 md:col-6 lg:col-6">
                        <label className='text-base'>{step3.address}
                            <strong>
                                {cancerStep4.address} &nbsp;
                                {cancerStep4.subDistrict} &nbsp;
                                {languageSelected === 'TH' ? cancerStep4.district.descTh : cancerStep4.district.descEn} &nbsp;
                                {languageSelected === 'TH' ? cancerStep4.province.descTh : cancerStep4.province.descEn} &nbsp;
                                {cancerStep4.zipCode}
                            </strong>
                        </label>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-12 md:col-6 lg:col-6">
                        <label className='text-base'>{step3.beneficiary}<strong>{displayData.benefit}</strong></label>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-12 md:col-6 lg:col-6">
                        <label className='text-base'>{step3.tex}<strong>{displayData.deduction}</strong></label>
                    </div>
                    <div className="col-12 md:col-6 lg:col-6">
                        <label className='text-base'>{step3.receive}<strong>{displayData.sendType}</strong></label>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-12 md:col-6 lg:col-6">
                        <label className='text-base'>{step3.promotionCode} {labelPromotion()}</label>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-12 md:col-6 lg:col-6">
                        <label className='text-base'>{step3.lable}</label>
                    </div>
                </div>
                <div className="grid text-center">

                    <div className="mt-3 w-full hidden-sm">
                        <Button className='col-12 md:col-4 lg:col-2 mr-2 mt-2' label={step3.button2} severity="secondary" onClick={() => editInformation()} disabled={isSubmit} />
                        <Button className='col-12 md:col-4 lg:col-2 mt-2' label={step3.button1} severity="danger" onClick={() => issuePolicy()} disabled={isSubmit} />
                    </div>

                    <div className="mt-3 w-full hidden-md">
                        <Button className='col-12 md:col-2 lg:col-2 mt-2' label={step3.button1} severity="danger" onClick={() => issuePolicy()} disabled={isSubmit} />
                        <Button className='col-12 md:col-2 lg:col-2 mr-2 mt-2' label={step3.button2} severity="secondary" onClick={() => editInformation()} disabled={isSubmit} />
                    </div>
                </div>
            </Panel>
        </div>
    );
}
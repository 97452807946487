import { useSelector } from 'react-redux';
import config from '../../../configs/config';

export default function HomeBanner() {

    const { languageSelected, languageEng, languageThai } = useSelector((state) => state.language)
    const images = languageSelected === 'TH' ? languageThai.banners : languageEng.banners;

    return (
        <>
            <div className="box-banner">
                {images.map((e, i) =>
                    <div className="mt-1" key={i}>
                        <a href={`${config.webUrlRedirect}/${e.url}`} target='_blank'><img src={e.original} alt="" /></a>
                    </div>
                )}
            </div>
        </>
    );
}
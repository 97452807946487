import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getPrefixesApi, getDistrictsApi, getProvincesApi, getRelationsApi } from '../services/api/master';

export const prefixes = createAsyncThunk('prefixes', async (_, { fulfillWithValue, rejectWithValue }) => {
    try {
        const { data } = await getPrefixesApi();
        return fulfillWithValue(data);
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

export const districts = createAsyncThunk('districts', async (_, { fulfillWithValue, rejectWithValue }) => {
    try {
        const { data } = await getDistrictsApi();
        return fulfillWithValue(data);
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

export const provinces = createAsyncThunk('provinces', async (_, { fulfillWithValue, rejectWithValue }) => {
    try {
        const { data } = await getProvincesApi();
        return fulfillWithValue(data);
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

export const relations = createAsyncThunk('relations', async (_, { fulfillWithValue, rejectWithValue }) => {
    try {
        const { data } = await getRelationsApi();
        return fulfillWithValue(data);
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

const masterSlice = createSlice({
    name: 'master',
    initialState: {
        loadingPrefixes: false,
        loadingDistricts: false,
        loadingProvinces: false,
        loadingRelations: false,
        prefixesData: sessionStorage.getItem('prefixesData') ? JSON.parse(sessionStorage.getItem('prefixesData')) : [],
        districtsData: sessionStorage.getItem('districtsData') ? JSON.parse(sessionStorage.getItem('districtsData')) : [],
        provincesData: sessionStorage.getItem('provincesData') ? JSON.parse(sessionStorage.getItem('provincesData')) : [],
        relationsData: sessionStorage.getItem('relationsData') ? JSON.parse(sessionStorage.getItem('relationsData')) : [],
    },
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(prefixes.fulfilled, (state, action) => {
                state.prefixesData = action.payload.data
                sessionStorage.setItem('prefixesData', JSON.stringify(action.payload.data))
                state.loadingPrefixes = false
            })
            .addCase(prefixes.pending, (state, action) => {
                state.loadingPrefixes = true
            })

            .addCase(districts.fulfilled, (state, action) => {
                state.districtsData = action.payload.data
                sessionStorage.setItem('districtsData', JSON.stringify(action.payload.data))
                state.loadingDistricts = false
            })
            .addCase(districts.pending, (state, action) => {
                state.loadingDistricts = true
            })

            .addCase(provinces.fulfilled, (state, action) => {
                state.provincesData = action.payload.data
                sessionStorage.setItem('provincesData', JSON.stringify(action.payload.data))
                state.loadingProvinces = false
            })
            .addCase(provinces.pending, (state, action) => {
                state.loadingProvinces = true
            })

            .addCase(relations.fulfilled, (state, action) => {
                state.relationsData = action.payload.data
                sessionStorage.setItem('relationsData', JSON.stringify(action.payload.data))
                state.loadingRelations = false
            })
            .addCase(relations.pending, (state, action) => {
                state.loadingRelations = true
            })
    }
})

export default masterSlice
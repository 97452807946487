import { useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useSelector } from 'react-redux';

export default function ProductCancerProtection() {

    const { cancerText } = useSelector((state) => state.language);
    let { Protection  } = cancerText.step1

    const [datas] = useState([
        {
            data1: Protection.column1.row1,
            data2: Protection.column2.row1,
            data3: Protection.column3.row1,
            data4: Protection.column4.row1,
        },
        {
            data1: Protection.column1.row2,
            data2: Protection.column2.row2,
            data3: Protection.column3.row2,
            data4: Protection.column4.row2,
        },
        {
            data1: Protection.column1.row3,
            data2: Protection.column2.row3,
            data3: Protection.column3.row3,
            data4: Protection.column4.row3,
        },
        {
            data1: Protection.column1.row4,
            data2: Protection.column2.row4,
            data3: Protection.column3.row4,
            data4: Protection.column4.row4,
        },
        {
            data1: Protection.column1.row5,
            data2: Protection.column2.row5,
            data3: Protection.column3.row5,
            data4: Protection.column4.row5,
        }
    ]);

    const plan1BodyTemplate = (data) => {
        return (
            data.data2 === 'Y' ?
                <i className="pi pi-check-circle text-xl" style={{ color: 'green' }}></i> :
                data.data2 === 'N' ? <i className="pi pi-times-circle text-xl" style={{ color: 'red' }}></i> : data.data2
        )
    }

    const plan2BodyTemplate = (data) => {
        return (
            data.data3 === 'Y' ?
                <i className="pi pi-check-circle text-xl" style={{ color: 'green' }}></i> :
                data.data3 === 'N' ? <i className="pi pi-times-circle text-xl" style={{ color: 'red' }}></i> : data.data3
        )
    }

    const plan3BodyTemplate = (data) => {
        return (
            data.data4 === 'Y' ?
                <i className="pi pi-check-circle text-xl" style={{ color: 'green' }}></i> :
                data.data4 === 'N' ? <i className="pi pi-times-circle text-xl" style={{ color: 'red' }}></i> : data.data4
        )
    }

    return (
        <div className='table-cancer-protection p-1'>
            <DataTable value={datas} stripedRows className='font-bold text-xl  hidden-sm'>
                <Column header={Protection.header1} className='text-left' field="data1" ></Column>
                <Column header={Protection.header2} field='data2' body={plan1BodyTemplate} ></Column>
                <Column header={Protection.header3} field='data3' body={plan2BodyTemplate} ></Column>
                <Column header={Protection.header4} field='data4' body={plan3BodyTemplate} ></Column>
            </DataTable>

            <div className="p-datatable p-component p-datatable-responsive-scroll p-datatable-striped font-bold text-xl hidden-md">
                <div className="p-datatable-wrapper">
                    <table className="p-datatable-table">
                        <thead className="p-datatable-thead">
                            <tr>
                                <th>{Protection.header2}</th>
                                <th>{Protection.header3}</th>
                                <th>{Protection.header4}</th>
                            </tr>
                        </thead>
                        <tbody className="p-datatable-tbody">
                            <tr>
                                <td colSpan="3">{Protection.column1.row1}</td>
                            </tr>
                            <tr>
                                <td>{Protection.column2.row1}</td>
                                <td>{Protection.column2.row2}</td>
                                <td>{Protection.column2.row3}</td>
                            </tr>
                            <tr className="p-row-odd">
                                <td colSpan="3">{Protection.column1.row2}</td>
                            </tr>
                            <tr className="p-row-odd">
                                <td>{Protection.column3.row1}</td>
                                <td>{Protection.column3.row2}</td>
                                <td>{Protection.column3.row3}</td>
                            </tr>
                            <tr>
                                <td colSpan="3">{Protection.column1.row3}</td>
                            </tr>
                            <tr>
                                <td>{Protection.column4.row1}</td>
                                <td>{Protection.column4.row2}</td>
                                <td>{Protection.column4.row3}</td>
                            </tr>
                            <tr className="p-row-odd">
                                <td colSpan="3">{Protection.column1.row4}</td>
                            </tr>
                            <tr className="p-row-odd">
                                <td>
                                    <i className="pi pi-times-circle text-xl text-red-600" ></i>
                                </td>
                                <td>
                                    <i className="pi pi-times-circle text-xl text-red-600" ></i>
                                </td>
                                <td>
                                    <i className="pi pi-check-circle text-xl text-green-600" ></i>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="3">{Protection.column1.row5}</td>
                            </tr>
                            <tr>
                                <td>
                                    <i className="pi pi-times-circle text-xl text-red-600" ></i>
                                </td>
                                <td>
                                    <i className="pi pi-times-circle text-xl text-red-600" ></i>
                                </td>
                                <td>
                                    <i className="pi pi-check-circle text-xl text-green-600"></i>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    );
}
import { Card } from 'primereact/card';
import { useSelector } from 'react-redux';
import { classNames } from 'primereact/utils';
import config from '../../../configs/config';

export default function HomeProduct() {

    const { languageSelected, languageEng, languageThai } = useSelector((state) => state.language)

    const topic = languageSelected === 'TH' ? languageThai.products.topic : languageEng.products.topic;
    const productItem = languageSelected === 'TH' ? languageThai.products.content : languageEng.products.content;

    const boxProducts = () => {
        return productItem.map((e, i) =>
            <div className="col-4 md:col-2 lg:col-2 product-item" key={i}>
                <a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/${e.link}`} alt={e.alt}>
                    <Card className="border-1 border-red-50 p-0">
                        <img src={e.icon} className='icon text-red' />
                        <b className={classNames(e.highlight ? 'pi pi-thumbs-up-fill item-promotion' : '')}></b>
                        <div>
                            <b className='product-title'>{e.title}</b>
                        </div>
                    </Card>
                </a>
            </div>
        );
    }

    return (
        <>
            {/* TO DO Product */}
            <div className="box-product">
                <h3 className='header-box'>{topic}</h3>
                <div className="text-center mt-3">
                    <div className="grid flex justify-content-center">
                        {boxProducts()}
                    </div>
                </div>
            </div>
        </>
    );
}
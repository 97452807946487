import { Outlet } from 'react-router-dom';
import DailogWarning from '../../../components/Dailog/DailogWarning';
import DailogWarningButton from '../../../components/Dailog/DailogWarningButton';
import DailogProtection from '../../../components/Dailog/DailogProtection';

export default function PageProduct() {

    return (
        <div>
            <DailogWarning />
            <DailogProtection/>
            <DailogWarningButton />
            <Outlet />
        </div>
    );
}

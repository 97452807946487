import { Button } from 'primereact/button';
import { useSelector } from 'react-redux';

export default function PageOops() {

    const { cancerText } = useSelector((state) => state.language);
    let { error } = cancerText

    const redirect = () => {
        window.location.href = '/product/cancer';
    };

    return (
        <div className='box-container'>
            <div className="wrapper">
                <div className="inner-wrapper">
                    <div className="grid">
                        <div className="col-12">
                            <h1 className='text-red-600'>{error.header}</h1>
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-12">
                            <p>{error.lable1}</p>
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-12">
                            <Button className='col-12 md:col-4 lg:col-2' onClick={redirect} label={error.button} severity="danger" />
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-12">
                            <strong>{error.lable2}</strong>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getPersonalBlackList } from '../services/api/personal';

export const checkPersonalBlackList = createAsyncThunk('checkPersonalBlackList', async (params, { fulfillWithValue, rejectWithValue }) => {
    const personalId = params.personalId;
    try {
        const { data } = await getPersonalBlackList(personalId);
        return fulfillWithValue(data);
    } catch (error) {
        return rejectWithValue(params);
    }
})

const componentSlice = createSlice({
    name: 'component',
    initialState: {
        isLoading: false,
        dailogWarning: { visible: false, title: '', message: '' },
        dailogProtect: { visible: false },
        dailogWarningButton: { visible: false, title: '', message: '', button: '' },
        isBlockPerson: false
    },
    reducers: {
        toggleLoading: (state, action) => {
            state.isLoading = action.payload
        },
        showDailogWarning: (state, action) => {
            state.dailogWarning = action.payload
        },
        showDailogWarningButton: (state, action) => {
            state.dailogWarningButton = action.payload
        },
        showDailogProtection: (state, action) => {
            state.dailogProtect = action.payload
        },
        setBlockPerson: (state, action) => {
            state.isBlockPerson = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(checkPersonalBlackList.fulfilled, (state, action) => {
                state.dailogWarning = { visible: false, title: action.payload.dailogTitle, message: action.payload.dailogMessage };;
                state.isBlockPerson = false;
            })
            .addCase(checkPersonalBlackList.rejected, (state, action) => {
                state.dailogWarning = { visible: true, title: action.payload.dailogTitle, message: action.payload.dailogMessage };
                state.isBlockPerson = true;
            })
    }
})

export default componentSlice

import axios from 'axios';
import config from '../../configs/config';

const baseUrl = window.location.origin + config.baseUrl;

export const getPersonalBlackList = (personalId) => {
    var header = {
        method: 'get',
        url: `${baseUrl}/api/Personals/Validate?personalId=${personalId}`,
        headers: {
            'Content-Type': 'application/json'
        }
    };
    return axios(header).then((response) => response);
}
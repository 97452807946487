import axios from 'axios';
import config from '../../configs/config';

const baseUrl = window.location.origin + config.baseUrl;

export const getPlanCodeApi = (params) => {
    var header = {
        method: 'get',
        url: `${baseUrl}/api/Cancers/PlanCode`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: params
    };
    return axios(header).then((response) => response);
}

export const cancerValidateApi = (params) => {
    var header = {
        method: 'post',
        url: `${baseUrl}/api/Cancers/Validate`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: params
    };
    return axios(header).then((response) => response);
}

export const getPlanCodePricesApi = (params) => {
    var header = {
        method: 'get',
        url: `${baseUrl}/api/Cancers/PlanCodePrices`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: params
    };
    return axios(header).then((response) => response);
}

export const postStep1Api = (params) => {
    var header = {
        method: 'post',
        url: `${baseUrl}/api/Cancers/Step1`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: params
    };
    return axios(header).then((response) => response);
}

export const putStep1Api = (id, params) => {
    var header = {
        method: 'put',
        url: `${baseUrl}/api/Cancers/Step1/${id}`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: params
    };
    return axios(header).then((response) => response);
}

export const postStep2Api = (params) => {
    var header = {
        method: 'post',
        url: `${baseUrl}/api/Cancers/Step2`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: params
    };
    return axios(header).then((response) => response);
}

export const putStep2Api = (id, params) => {
    var header = {
        method: 'put',
        url: `${baseUrl}/api/Cancers/Step2/${id}`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: params
    };
    return axios(header).then((response) => response);
}

export const postStep3Api = (params) => {
    var header = {
        method: 'post',
        url: `${baseUrl}/api/Cancers/Step3`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: params
    };
    return axios(header).then((response) => response);
}

export const putStep3Api = (id, params) => {
    var header = {
        method: 'put',
        url: `${baseUrl}/api/Cancers/Step3/${id}`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: params
    };
    return axios(header).then((response) => response);
}

export const postStep4Api = (params) => {
    var header = {
        method: 'post',
        url: `${baseUrl}/api/Cancers/Step4`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: params
    };
    return axios(header).then((response) => response);
}

export const putStep4Api = (id, params) => {
    var header = {
        method: 'put',
        url: `${baseUrl}/api/Cancers/Step4/${id}`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: params
    };
    return axios(header).then((response) => response);
}

export const postStep5Api = (params) => {
    var header = {
        method: 'post',
        url: `${baseUrl}/api/Cancers/Step5`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: params
    };
    return axios(header).then((response) => response);
}

export const putStep5Api = (id, params) => {
    var header = {
        method: 'put',
        url: `${baseUrl}/api/Cancers/Step5/${id}`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: params
    };
    return axios(header).then((response) => response);
}

export const postStep6Api = (params) => {
    var header = {
        method: 'post',
        url: `${baseUrl}/api/Cancers/Step6`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: params
    };
    return axios(header).then((response) => response);
}

export const putStep6Api = (id, params) => {
    var header = {
        method: 'put',
        url: `${baseUrl}/api/Cancers/Step6/${id}`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: params
    };
    return axios(header).then((response) => response);
}

export const postIssuePolicyApi = (params) => {
    var header = {
        method: 'post',
        url: `${baseUrl}/api/Cancers/Issue`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: params
    };
    return axios(header).then((response) => response);
}
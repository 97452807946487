import { Panel } from 'primereact/panel';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import cancerSlice, { postStep1, putStep1 } from '../../../../../Redux/cancerSlice';
import promotionsSlice from '../../../../../Redux/promotionsSlice';
import { promotionsApplyCode } from '../../../../../Redux/promotionsSlice';
import { pushToDataLayer } from '../../../../../utilities/gtm'
import { Card } from 'primereact/card';
import { RadioButton } from 'primereact/radiobutton';
import { classNames } from 'primereact/utils';
import { chunkArrayInGroups } from '../../../../../services/common';
import { useEffect } from 'react';

export default function ProductCancerSelection(props) {

    const { cancerText, languageSelected } = useSelector((state) => state.language);
    let { premuim, brochure, promotionCode } = cancerText.step1

    const dispatch = useDispatch();

    const { cancerPlanCodeData, cancerPlanCodePricesData, loadingPlanCode, cancerStep1, cancerId, cancerPremium } = useSelector((state) => state.cancer)
    const { setPromotionCode } = promotionsSlice.actions
    const { codeAvailable, promotionDesc } = useSelector((state) => state.promotions)
    const { setCancerStep1, setStepActive, setCancerPremium, setDocumentLanguage } = cancerSlice.actions

    const defaultValues = {
        planSelected: 'CIP1',
        total: 999
    }

    const { control, formState: { errors }, handleSubmit, setValue } = useForm({ defaultValues });

    useEffect(() => {
        const planCode = cancerPlanCodeData && cancerPlanCodeData.length ? cancerPlanCodeData[0].planCode : 'CIP1'
        const priceData = cancerPlanCodePricesData && cancerPlanCodePricesData.length ? cancerPlanCodePricesData.find(x => x.planCode === planCode) : 999
        setValue('planSelected', cancerStep1 && cancerStep1.planCode ? cancerStep1.planCode : planCode)
        setValue('total', cancerPremium ? cancerPremium : priceData.total)

        if (cancerPlanCodeData.length && !loadingPlanCode) {
            pushToDataLayer('view_item',
                {
                    "currency": "THB",
                    "items": cancerPlanCodeData.map(e => ({ item_id: e.planCode, item_name: e.planDesc })),
                    "item_brand": 'e-cancer',
                    "item_category": 'Health insurance'
                })
        }

    }, [dispatch, cancerPlanCodeData]);

    const selectPlan = (planCode, total) => {
        let params = {
            cancerId: cancerId,
            planCode: planCode
        }
        if (cancerStep1) {
            dispatch(putStep1(params));
        }
        else {
            dispatch(postStep1(params));
        }
        dispatch(setStepActive(1));
        dispatch(setCancerPremium(total));
        pushToDataLayer('add_to_cart',
            {
                "currency": "THB",
                "value": total,
                "items": [{
                    "item_id": `e-cencer-${planCode}`,
                    "item_name": `e-cencer-${planCode}`,
                    "price": total,
                    "item_brand": 'e-cancer',
                    "item_category": 'Health insurance'
                }]
            })
        dispatch(setDocumentLanguage(languageSelected))
    }

    const checkPromotionsApplyCode = (event) => {
        dispatch(promotionsApplyCode({ code: event.target.value, productNameId: 1 }));
        dispatch(setPromotionCode(event.target.value));
    };

    const downloadBrochure = () => {
        window.open(brochure.link, '_blank')
    };

    const labelPromotion = () => {
        const { th, en } = promotionDesc;
        let classLabel = 'text-green-700';
        let msg = promotionCode.message.valid;
        if (codeAvailable && codeAvailable > 0) {
            if (codeAvailable <= 5) {
                classLabel = 'text-orange-500';
                msg = promotionCode.message.codeAmuont1 + codeAvailable + promotionCode.message.codeAmuont2;
            }
        } else {
            classLabel = 'text-red-600';
            msg = languageSelected === 'TH' ? th : en;
        }
        return <>
            <strong><label className={classLabel}>{msg}</label></strong>
        </>
    }

    const onSubmit = (data) => {
        selectPlan(data.planSelected, data.total);
    };


    const selectedPlan = (planCode, total) => {
        setValue('planSelected', planCode)
        setValue('total', total)
    }

    const menuSelection = () => {
        let rawData = cancerPlanCodeData.map(e => {
            const priceData = cancerPlanCodePricesData.find(x => x.planCode === e.planCode);
            const total = priceData ? priceData.total : 0
            return ({ ...e, priceYear: total, priceMonth: total / 12 })
        })
        const item = chunkArrayInGroups(rawData, 3);
        return item.map((e, i) =>

            <div className="grid" key={i}>
                {e.map((x, j) =>
                    <div className="col-12 md:col-4 lg:col-4" key={j}>
                        <Card>
                            <div className="p-card-content m-3 p-0" onClick={() => selectedPlan(x.planCode, x.priceYear)}>
                                <h1>
                                    {premuim.title}
                                    {/* Mobile only */}
                                    <Controller name="planSelected" control={control} rules={{ required: false }} render={({ field, fieldState }) => (
                                        <RadioButton id={field.name} value={x.planCode} onChange={(e) => selectedPlan(e.value, x.priceYear)} checked={field.value === x.planCode} className={classNames('hidden-md mt-2 float-right ' + { 'p-invalid': fieldState.invalid })} />
                                    )} />
                                    {/* Mobile only */}
                                </h1>
                                <h2 className='text-red-600'>{(x.cI01 + x.cI02).toLocaleString()} {premuim.currency}</h2>
                                <span>{premuim.lable1} <b className='text-2xl'>{x.priceYear.toLocaleString()}</b> <b>{premuim.lable2}</b></span>
                                <h4>{premuim.lable3} {x.priceMonth.toFixed()} {premuim.lable4}</h4>
                                <div className="text-center mt-3 hidden-sm">
                                    <Button className='col-12 md:col-10 lg:col-10' label={premuim.button} severity="danger" onClick={() => selectPlan(x.planCode, x.priceYear)} />
                                </div>
                            </div>
                            <div className="p-card-footer p-2 text-center" onClick={() => props.planRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })}>
                                <h3>{premuim.more}</h3>
                            </div>
                        </Card>
                    </div>
                )}
            </div>
        )
    }

    return (
        <div className='box-product-cancer'>
            <form onSubmit={handleSubmit(onSubmit)}>
                {menuSelection()}
                {/* Mobile only */}
                <Button className='col-12 md:col-10 lg:col-10 mb-2 hidden-md' label={premuim.button} severity="danger" type='submit' />
                {/* Mobile only */}
            </form>
            <div className="grid promotion-grid">
                <div className="col-12 md:col-4 lg:col-4 flex flex-column gap-2 mt-1">
                    <div className="card promotion-card">
                        <Panel className="panel-cancer">
                            <h2 className='text-red-600'>{promotionCode.title}</h2>
                            <Controller name="promotionCode" control={control} rules={{ required: '' }} render={({ field, fieldState }) => (
                                <InputText id={field.promotionCode} {...field} className="p-invalid-sm col-12"
                                    onBlur={(e) => { checkPromotionsApplyCode(e) }} />
                            )} />
                            {labelPromotion()}
                        </Panel>
                    </div>
                </div>
            </div>
            <div className="grid promotion-grid">
                <div className="col-12 md:col-4 lg:col-4 flex flex-column gap-2">
                    <Button className='col-12 mt-1' label={brochure.button} onClick={() => downloadBrochure()} severity="danger" />
                </div>
            </div>
        </div>
    );
}
import { Image } from 'primereact/image';
import { Button } from 'primereact/button';
import { useSelector } from 'react-redux';
import imgThankyou from '../../../../../Assets/images/cancer/thankyou/thankyou.jpg';
import { useParams } from 'react-router-dom';


export default function RenewalThankyou() {

    const { refcode } = useParams();

    const redirect = () => {
        window.location.href = '/';
    };

    const { languageEng, languageThai, languageSelected } = useSelector((state) => state.language);
    let { thankyou } = languageSelected == "TH" ? languageThai : languageEng

    return (
        <div className='box-container'>
            <div className="wrapper">
                <div className="inner-wrapper">
                    <div className="grid">
                        <div className="col-12">
                            <Image src={imgThankyou} alt="Image" width="120" />
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-12">
                            <h1 className='text-red-600'>{thankyou.header}</h1>
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-12">
                            <p>{thankyou.lable1}</p>
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-12">
                            <p>{thankyou.lable2}<br />
                                <strong>{thankyou.refCode} <span className='text-red-600'>{refcode}</span></strong></p>
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-12">
                            <Button className='col-12 md:col-4 lg:col-3 btn-danger' onClick={redirect} label={thankyou.button} severity="danger" rounded />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
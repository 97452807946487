import { useState, useRef } from "react"
import { Button } from 'primereact/button'
import { Card } from 'primereact/card'
import { OverlayPanel } from 'primereact/overlaypanel'
import { useSelector } from 'react-redux'
import {
    FacebookShareButton,
    FacebookIcon,
    LineShareButton,
    LineIcon,
    FacebookMessengerShareButton,
    FacebookMessengerIcon,
    TwitterShareButton,
    TwitterIcon
} from "react-share"
import config from "../../../configs/config"

export default function HomeBlog() {

    const [shareUrl, setShareUrl] = useState('')

    const op = useRef(null)

    const { languageSelected, languageEng, languageThai } = useSelector((state) => state.language)
    const { topic, all, buttonDetail } = languageSelected === 'TH' ? languageThai.blogs : languageEng.blogs

    const share = (e, link) => {
        op.current.toggle(e)
        setShareUrl(link)
    }

    return (
        <>
            {/* TO DO Blog */}
            <div className="box-blog">
                <h3 className='header-box mb-3'>{topic}</h3>
                <OverlayPanel ref={op}>
                    <div className="text-center">
                        <FacebookShareButton url={shareUrl}>
                            <FacebookIcon size={40} round={true} />
                        </FacebookShareButton> &nbsp
                        <LineShareButton url={shareUrl}>
                            <LineIcon size={40} round={true} />
                        </LineShareButton> &nbsp
                        <FacebookMessengerShareButton url={shareUrl}>
                            <FacebookMessengerIcon size={40} round={true} />
                        </FacebookMessengerShareButton> &nbsp
                        <TwitterShareButton url={shareUrl}>
                            <TwitterIcon size={40} round={true} />
                        </TwitterShareButton>
                    </div>
                </OverlayPanel>
                <div className="grid">
                    <div className="col-12 md:col-4 lg:col-4">
                        <Card>
                            <div className="p-card-content">
                                <img src={`${config.webUrlRedirect}/storage/article/82/why-officers-should-have-hospital-cash-insurance.jpg`} alt="" className='w-full' />
                            </div>
                            <div className="p-card-footer p-0">
                                <div className="p-2" onClick={() => window.location.href = `${config.webUrlRedirect}/th/article/why-officers-should-have-hospital-cash-insurance`} >
                                    <h5 className="text-red m-0">
                                        ทำไมพนักงานประจำ ต้องมีประกันชดเชยรายได้
                                    </h5>
                                    <div className="blog-desc">
                                        <small>
                                            ประกันที่บริษัทเสนอให้อาจจะไม่ครอบคลุม นั่นเป็นเหตุผลว่าทำไมยังต้องมีประกันชดเชยรายได้ บทความนี้ทูน โพรเทคจะมาแนะนำว่าทำไมพนักงานประจำ ต้องมีประกันชดเชยรายได้
                                        </small>
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="col-8 text-center m-0 p-0 text-red pl-3 mb-2 text-1xl">
                                        {/* ดูรายละเอียด */}
                                        <Button label={buttonDetail} rounded outlined severity="danger" className="w-full"
                                            onClick={() => window.location.href = `${config.webUrlRedirect}/th/article/why-officers-should-have-hospital-cash-insurance`} />
                                    </div>
                                    <div className="col-4 text-center m-0 p-0 text-red text-1xl">
                                        <Button icon="pi pi-share-alt" rounded outlined severity="danger" aria-label="Share" onClick={(e) => share(e, `${config.webUrlRedirect}/th/article/why-officers-should-have-hospital-cash-insurance`)} />
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>

                    <div className="col-12 md:col-4 lg:col-4">
                        <Card>
                            <div className="p-card-content"  >
                                <img src={`${config.webUrlRedirect}/storage/article/81/how-to-choose-child-accident-insurance-for-child.jpg`} alt="" className='w-full' />
                            </div>
                            <div className="p-card-footer p-0">
                                <div className="p-2" onClick={() => window.location.href = `${config.webUrlRedirect}/th/article/how-to-choose-child-accident-insurance-for-child`}>
                                    <h5 className="text-red m-0">
                                        How To เลือกประกันอุบัติเหตุเด็กให้ลูกรัก
                                    </h5>
                                    <div className="blog-desc">
                                        <small>
                                            ใกล้เปิดเทอมผู้ปกครองไม่สามารถดูแลเด็ก ๆ ได้ตลอด การมีประกันอุบัติเหตุเด็กถือเป็นตัวช่วยที่ดี บทความนี้ ทูน โพรเทค มีวิธีการเลือกประกันอุบัติเหตุเด็กให้ลูกรักมาแนะนำ
                                        </small>
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="col-8 text-center m-0 text-red p-0 pl-3 mb-2 text-1xl">
                                        {/* ดูรายละเอียด */}
                                        <Button label={buttonDetail} rounded outlined severity="danger" className="w-full"
                                            onClick={() => window.location.href = `${config.webUrlRedirect}/th/article/how-to-choose-child-accident-insurance-for-child`} />
                                    </div>
                                    <div className="col-4 text-center m-0 p-0 text-red text-1xl">
                                        <Button icon="pi pi-share-alt" rounded outlined severity="danger" aria-label="Share" onClick={(e) => share(e, `${config.webUrlRedirect}/th/article/why-officers-should-have-hospital-cash-insurance`)} />
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>

                    <div className="col-12 md:col-4 lg:col-4">
                        <Card>
                            <div className="p-card-content">
                                <img src={`${config.webUrlRedirect}/storage/article/80/how-to-prepare-your-house-for-rainy-season.jpg`} alt="" className='w-full' />
                            </div>
                            <div className="p-card-footer p-0">
                                <div className="p-2" onClick={() => window.location.href = `${config.webUrlRedirect}/th/article/how-to-prepare-your-house-for-rainy-season`} >
                                    <h5 className="text-red m-0">
                                        วิธีเช็กบ้านให้พร้อมรับหน้าฝน
                                    </h5>
                                    <div className="blog-desc">
                                        <small>
                                            ใกล้เข้าหน้าฝนแล้ว วันนี้ ทูน โพรเทค มาแชร์วิธีเช็กบ้านให้พร้อมสำหรับหน้าฝน เพื่อความปลอดภัยในชีวิตและทรัพย์สินของทุกท่าน พร้อมทั้งมีประกันภัยบ้าน myHomeSmart มาแนะนำให้กับทุกคน
                                        </small>
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="col-8 text-center m-0 text-red p-0 pl-3 mb-2 text-1xl">
                                        {/* ดูรายละเอียด */}
                                        <Button label={buttonDetail} rounded outlined severity="danger" className="w-full"
                                            onClick={() => window.location.href = `${config.webUrlRedirect}/th/article/how-to-prepare-your-house-for-rainy-season`} />
                                    </div>
                                    <div className="col-4 text-center m-0 text-red p-0 text-1xl">
                                        <Button icon="pi pi-share-alt" rounded outlined severity="danger" aria-label="Share" onClick={(e) => share(e, `${config.webUrlRedirect}/th/article/why-officers-should-have-hospital-cash-insurance`)} />
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>

                </div>

                <h5 className="text-center mt-1">
                    <a href={`${config.webUrlRedirect}/th/article`} target='_blank' className='text-900 no-underline'>
                        {all}
                        <i className="pi pi-arrow-right pl-2 text-sm"></i>
                    </a>
                </h5>

            </div >
        </>
    )
}
import { Card } from 'primereact/card';
import image from '../../../Assets/images/BannerTuneProtect.webp';
import { useSelector } from 'react-redux';
import { chunkArrayInGroups } from '../../../services/common';

export default function HomeAbout() {

    const { languageSelected, languageEng, languageThai } = useSelector((state) => state.language)
    const topic = languageSelected === 'TH' ? languageThai.about.topic : languageEng.about.topic;
    const title1 = languageSelected === 'TH' ? languageThai.about.title1 : languageEng.about.title1;
    const subTitle1 = languageSelected === 'TH' ? languageThai.about.subTitle1 : languageEng.about.subTitle1;
    const title2 = languageSelected === 'TH' ? languageThai.about.title2 : languageEng.about.title2;
    const items = languageSelected === 'TH' ? languageThai.about.items : languageEng.about.items;

    const displayItem = () => {
        const item = chunkArrayInGroups(items, 2);
        return (
            <div className="p-card-content">
                <div className="banner-about">
                    <img src={image} alt="" className='w-full' />
                    <div className="content-about">
                        <div className='pl-1'>
                            <label className='font-bold text-2xl'>
                                {title1}
                            </label>
                        </div>
                        <div className='pl-3'>
                            <label className='font-bold text-lg'>{subTitle1}</label>
                        </div>
                    </div>
                </div>
                <div className="p-card-footer">
                    <h3 className='pl-3 mb-3'>{title2}</h3>
                    <div className="grid">
                        {item.map((e, i) =>
                            <div className="col-12 md:col-6 lg:col-6 p-0" key={i}>
                                {e.map((x, j) =>
                                    <div className="grid" key={j}>
                                        <div className="col-2 pl-5">
                                            <div className="number-circle text-center text-red">{x.number}</div>
                                        </div>
                                        <div className="col-10 px-5">
                                            <div>
                                                <b className="text-red text-lg">{x.title}</b>
                                            </div>
                                            <div className='text-lg'>
                                                {x.subTitle}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>)
    }

    const displayItemMobile = () => {
        const item = chunkArrayInGroups(items, 2);
        return (
            <div className="p-card-content">
                <div className="banner-about">
                    <img src={image} alt="" className='w-full' />
                    <div className="content-about">
                        <div className='pl-1'>
                            <label className='font-bold text-lg'>
                                {title1}
                            </label>
                        </div>
                        <div className='pl-3'>
                            <label className='font-bold'>{subTitle1}</label>
                        </div>
                    </div>
                </div>
                <div className="p-card-footer">
                    <h4 className='pl-3'>{title2}</h4>
                    <div className="grid">
                        {item.map((e, i) =>
                            <div className="col-12 md:col-6 lg:col-6 p-0" key={i}>
                                {e.map((x, j) =>
                                    <div className="grid" key={j}>
                                        <div className="col-1">
                                        </div>
                                        <div className="col-10">
                                            <div>
                                                <b className="text-red text-lg">{x.number}. {x.title}</b>
                                            </div>
                                            <div className='text-lg'>
                                                {x.subTitle}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>)
    }

    return (
        <>
            {/* TO DO About tune protect */}
            <div className="box-about">
                <h3 className='header-box mb-3'>{topic}</h3>
                <Card>
                    <div className="about-howto">
                        {displayItem()}
                    </div>
                    <div className="about-howto-mobile">
                        {displayItemMobile()}
                    </div>
                </Card>
            </div>
        </>
    );
}
import logo from '../../Assets/images/tune-logo.png';
import youtube from '../../Assets/images/icons/youtube.png';
import facebook from '../../Assets/images/icons/facebook.png';
import instragram from '../../Assets/images/icons/instagram.png';
import linkedin from '../../Assets/images/icons/linkedin.png';
import tiktok from '../../Assets/images/icons/tik-tok.png';
import line from '../../Assets/images/icons/line.png';
import { useSelector } from 'react-redux';
import config from '../../configs/config';
import moment from 'moment';

export default function Footer(props) {

    const { languageSelected } = useSelector((state) => state.language)

    const menuFooter = () => {
        if (languageSelected === 'TH') {
            return <>
                <div className="grid">
                    <div className="col-12 md:col-3 lg:col-3">
                        <h4 className='border-bottom-1 border-300'>ผลิตภัณฑ์</h4>
                    </div>
                    <div className="col-12 md:col-3 lg:col-3">
                        <h4 className='border-bottom-1 border-300'>บริการ</h4>
                    </div>
                    <div className="col-12 md:col-3 lg:col-3">
                        <h4 className='border-bottom-1 border-300'>เรียกร้องสินไหม</h4>
                    </div>
                    <div className="col-12 md:col-3 lg:col-3">
                        <h4 className='border-bottom-1 border-300'>เกี่ยวกับ Tune Protect</h4>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-12 md:col-3 lg:col-3">
                        <ul className='list-none p-0' ref={props.bussinesRef}>
                            <li className='font-bold'>ประกันภัยสำหรับบุคคล</li>
                            <li><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/product/health`}>ประกันสุขภาพและโรคร้ายแรง</a></li>
                            {/* <li><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/product/pa-choice-insurance`}>ประกันภัยอุบัติเหตุส่วนบุคคล</a></li> */}
                            <li><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/product/travel-insurance`}>ประกันภัยการเดินทาง</a></li>
                            <li><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/product/fire-myhomeplus`}>ประกันภัยสำหรับที่อยู่อาศัย</a></li>
                            <li className='font-bold'>ประกันภัยสำหรับธุรกิจ</li>
                            <li><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/product/construction-all-risk-insurance`}>ประกันความเสี่ยงภัยทุกชนิดสำหรับงานรับเหมาก่อสร้าง/ติดตั้งเครื่องจักร</a></li>
                            <li><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/product/machinery-insurance`}>ประกันภัยความเสี่ยงภัยทุกชนิดของเครื่องจักรที่ใช้ในงานก่อสร้าง</a></li>
                            <li><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/product/all-industry-insurance`}>ประกันความเสี่ยงภัยทุกชนิดของอุตสาหกรรม</a></li>
                            <li><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/product/liability-insurance`}>ความรับผิดต่อบุคคลภายนอก</a></li>
                            <li><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/product/business-interruption-insurance`}>ประกันภัยธุรกิจหยุดชะงัก</a></li>
                            <li><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/product/marine-logistics-insurance`}>ประกันภัยทางทะเล และขนส่ง</a></li>
                            <li><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/product/fire-insurance`}>ประกันอัคคีภัย</a></li>
                        </ul>
                        <h4 className='border-bottom-1 border-300'><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/promotion`}>โปรโมชั่น</a></h4>
                        <h4 className='border-bottom-1 border-300'><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/news`}>ข่าวสารและกิจกรรม</a></h4>
                    </div>
                    <div className="col-12 md:col-3 lg:col-3">
                        <ul className='list-none p-0'>
                            <li><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/service/evacrepat`}>EVAC/REPAT</a></li>
                            <li><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/service/fixit`}>Fixit</a></li>
                            <li><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/service/health2go`}>Health2Go</a></li>
                            <li><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/service/mso`}>myEliteDoctor</a></li>
                            <li><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/taxdeduction`}>แจ้งใช้สิทธิลดหย่อนภาษี</a></li>
                            <li><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/service/dfit`}>Dfit</a></li>
                            <li><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/service/bloodtest`}>BloodTest</a></li>
                        </ul>
                    </div>
                    <div className="col-12 md:col-3 lg:col-3">
                        <ul className='list-none p-0'>
                            <li className='font-bold'>การประกันภัยเดินทาง</li>
                            <li><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/claim/travel_medical_reimbursement`}>การเรียกร้องค่าสินไหมทดแทนกรณีรักษาพยาบาลจากการเจ็บป่วย</a></li>
                            <li><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/claim/travel_accident_reimbursement`}>การเรียกร้องค่าสินไหมทดแทนกรณีรักษาพยาบาลจากอุบัติเหตุ</a></li>
                            <li><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/claim/travel_cancellation_delay_baggage_reimbursement`}>การเรียกร้องค่าสินไหมทดแทนกรณีการเลื่อนหรือการบอกเลิกการเดินทาง / การล่าช้าในการเดินทาง / การสูญเสียหรือเสียหายของกระเป๋าเดินทาง</a></li>
                            <li className='font-bold'>การประกันภัยอุบัติเหตุส่วนบุคคล</li>
                            <li><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/claim/personal_accident_reimbursement`}>การเรียกร้องค่าสินไหมทดแทนกรณีประกันภัยอุบัติเหตุส่วนบุคคล</a></li>
                            <li className='font-bold'>การประกันภัยสุขภาพ</li>
                            <li><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/claim/covid_infection_vaccination_reimbursement`}>การเรียกร้องค่าสินไหมทดแทนกรณีติดเชื้อไวรัสโคโรนา 2019 / แพ้วัคซีนไวรัสโคโรนา 2019</a></li>
                            <li><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/claim/critical_illness_and_diabetes_reimbursement`}>การเรียกร้องค่าสินไหมทดแทนกรณีโรคร้ายแรง / โรคเบาหวาน</a></li>
                            <li className='font-bold'>การประกันภัยบ้าน</li>
                            <li><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/claim/home_protection_reimbursement`}>การเรียกร้องค่าสินไหมทดแทนประกันภัยบ้าน</a></li>
                            <li className='font-bold'>การประกันภัยรถยนต์</li>
                            <li><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/claim/personal_auto_reimbursement`}>การเรียกร้อนค่าสินไหมทดแทนประกันภัยรถยนต์</a></li>
                            <li className='font-bold'>การประกันภัยความเสี่ยงภัยทรัพย์สิน / อัคคีภัย</li>
                            <li><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/claim/industrial_all_risk_reimbursement`}>การเรียกร้องค่าสินไหมทดแทนประเภทความเสี่ยงภัยทรัพย์สิน</a></li>
                            <li><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/claim/commercial_fire_reimbursement`}>การเรียกร้องค่าสินไหมทดแทนประเภทอัคคีภัย</a></li>
                            <li className='font-bold'>การประกันภัยความรับผิดต่อบุคคลภายนอก</li>
                            <li><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/claim/public_liability_reimbursement`}>การเรียกร้องค่าสินไหมทดแทนความรับผิดต่อบุคคลภายนอก</a></li>
                            <li className='font-bold'>การประกันภัยทางทะเลและขนส่ง</li>
                            <li><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/claim/marine`}>การเรียกร้องค่าสินไหมทดแทนประกันภัยทางทะเลและขนส่ง</a></li>
                        </ul>
                        <h4 className='border-bottom-1 border-300'><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/article`}>บทความแนะนำ</a></h4>
                        <h4 className='border-bottom-1 border-300'><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/contactus2`}>ติดต่อเรา</a></h4>
                    </div>
                    <div className="col-12 md:col-3 lg:col-3">
                        <ul className='list-none p-0'>
                            <li className='font-bold'><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/aboutus/organization`}>ประวัติองค์กร</a></li>
                            <li><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/aboutus/corporate-governance`}>การกำกับดูแลกิจการ</a></li>
                            <li><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/aboutus/annual_report`}>รายงานประจำปี</a></li>
                            <li><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/aboutus/financial-statement`}>ข้อมูลสำคัญทางการเงิน</a></li>
                            <li><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/contactus2`}>ติดต่อเรา</a></li>
                            <li><a href="https://www.tuneprotect.com/corporate/group/about-us">Tune Protect Group</a></li>
                            <li className='font-bold'>พันธมิตร</li>
                            <li><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/partner/hospital`}>โรงพยาบาล</a></li>
                            <li><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/partner/garage`}>อู่ในเครือ</a></li>
                            <li><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/partner/service_center`}>ศูนย์บริการ</a></li>
                            <li><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/partner/auto_glass`}>ร้านกระจกในเครือ</a></li>
                        </ul>
                    </div>
                </div>
            </>
        } else {
            return <>
                <div className="grid">
                    <div className="col-12 md:col-3 lg:col-3">
                        <h4 className='border-bottom-1 border-300'>Product</h4>
                    </div>
                    <div className="col-12 md:col-3 lg:col-3">
                        <h4 className='border-bottom-1 border-300'>Services</h4>
                    </div>
                    <div className="col-12 md:col-3 lg:col-3">
                        <h4 className='border-bottom-1 border-300'>Claims Service</h4>
                    </div>
                    <div className="col-12 md:col-3 lg:col-3">
                        <h4 className='border-bottom-1 border-300'>About Tune Protect</h4>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-12 md:col-3 lg:col-3">
                        <ul className='list-none p-0' ref={props.bussinesRef}>
                            <li className='font-bold'>Personal Insurance</li>
                            <li><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/product/health`}>Health and Critical Illness Insurance</a></li>
                            {/* <li><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/product/pa-choice-insurance`}>PA Choice</a></li> */}
                            <li><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/product/travel-insurance`}>Travel Insurance</a></li>
                            <li><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/product/fire-myhomeplus`}>Fire insurance</a></li>
                            <li className='font-bold'>Business Insurance</li>
                            <li><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/product/construction-all-risk-insurance`}>Contractor/Erection All Risks Insurance</a></li>
                            <li><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/product/machinery-insurance`}>Contractor's Plant All Risks Insurance</a></li>
                            <li><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/product/all-industry-insurance`}>Industrial All Risks Insurance</a></li>
                            <li><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/product/liability-insurance`}>Public Liability Insurance</a></li>
                            <li><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/product/business-interruption-insurance`}>Business Interruption</a></li>
                            <li><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/product/marine-logistics-insurance`}>Marine and Transportation Insurance</a></li>
                            <li><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/product/fire-insurance`}>Fire Insurance</a></li>
                        </ul>
                        <h4 className='border-bottom-1 border-300'><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/promotion`}>Promotion</a></h4>
                        <h4 className='border-bottom-1 border-300'><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/news`}>News & Activity</a></h4>
                    </div>
                    <div className="col-12 md:col-3 lg:col-3">
                        <ul className='list-none p-0'>
                            <li><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/service/evacrepat`}>EVAC/REPAT</a></li>
                            <li><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/service/fixit`}>Fixit</a></li>
                            <li><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/service/health2go`}>Health2Go</a></li>
                            <li><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/service/mso`}>myEliteDoctor</a></li>
                            <li><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/taxdeduction`}>Insurance Premium for Income Tax Deduction</a></li>
                            <li><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/service/dfit`}>Dfit</a></li>
                            <li><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/service/bloodtest`}>BloodTest</a></li>
                        </ul>
                    </div>
                    <div className="col-12 md:col-3 lg:col-3">
                        <ul className='list-none p-0'>
                            <li className='font-bold'>Travel Insurance</li>
                            <li><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/claim/travel_medical_reimbursement`}>Travel Medical Reimbursement</a></li>
                            <li><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/claim/travel_accident_reimbursement`}>Travel Accident Reimbursement</a></li>
                            <li><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/claim/travel_cancellation_delay_baggage_reimbursement`}>Travel Cancellation/ Delay/ Baggage Reimbursement</a></li>
                            <li className='font-bold'>Personal Accident</li>
                            <li><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/claim/personal_accident_reimbursement`}>Personal Accident Reimbursement</a></li>
                            <li className='font-bold'>Health</li>
                            <li><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/claim/covid_infection_vaccination_reimbursement`}>Covid Infection/ Vaccination Reimbursement</a></li>
                            <li><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/claim/critical_illness_and_diabetes_reimbursement`}>Critical Illness and Diabetes Reimbursement</a></li>
                            <li className='font-bold'>Home</li>
                            <li><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/claim/home_protection_reimbursement`}>Home Protection Reimbursement</a></li>
                            <li className='font-bold'>Auto</li>
                            <li><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/claim/personal_auto_reimbursement`}>Personal Auto Reimbursement</a></li>
                            <li className='font-bold'>Industrial All Risk/ Fire</li>
                            <li><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/claim/industrial_all_risk_reimbursement`}>Industrial All Risk Reimbursement</a></li>
                            <li><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/claim/commercial_fire_reimbursement`}>Commercial Fire Reimbursement</a></li>
                            <li className='font-bold'>Public Liability</li>
                            <li><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/claim/public_liability_reimbursement`}>Public Liability Reimbursement</a></li>
                            <li className='font-bold'>Marine</li>
                            <li><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/claim/marine`}>Marine</a></li>
                        </ul>
                        <h4 className='border-bottom-1 border-300'><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/article`}>Blog</a></h4>
                        <h4 className='border-bottom-1 border-300'><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/contactus2`}>Contract Us</a></h4>
                    </div>
                    <div className="col-12 md:col-3 lg:col-3">
                        <ul className='list-none p-0'>
                            <li className='font-bold'>Company Profile</li>
                            <li><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/aboutus/organization`}>Company Profile</a></li>
                            <li><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/aboutus/corporate-governance`}>Corporate Governance</a></li>
                            <li><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/aboutus/annual_report`}>Annual Report</a></li>
                            <li><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/aboutus/financial-statement`}>Financials & Investments</a></li>
                            <li><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/contactus2`}>Contact Us</a></li>
                            <li><a href="https://www.tuneprotect.com/corporate/group/about-us">Tune Protect Group</a></li>
                            <li className='font-bold'>Our Partner</li>
                            <li><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/partner/hospital`}>Hospitals</a></li>
                            <li><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/partner/garage`}>Garages</a></li>
                            <li><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/partner/service_center`}>Service Centers</a></li>
                            <li><a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/partner/auto_glass`}>Auto Glass</a></li>
                        </ul>
                    </div>
                </div>
            </>
        }
    }

    return (
        <>
            <footer className="box-footer m-2 hidden-sm">
                <div className='grid social-desktop'>
                    <div className="col-2">
                        <img src={logo} className='h-3rem' />
                    </div>
                    <div className="col-10 text-right mt-3">
                        <ul className='list-none inline-flex'>
                            <li className='px-1'>
                                <a href="https://www.youtube.com/@tuneprotectthailand2058"><img className='w-6' src={youtube} alt="" /></a>
                            </li>
                            <li className='px-1'>
                                <a href="https://www.facebook.com/TuneProtectThailand"><img className='w-6' src={facebook} alt="" /></a>
                            </li>
                            <li className='px-1'>
                                <a href="https://www.instagram.com/tuneprotect.thailand/"><img className='w-6' src={instragram} alt="" /></a>
                            </li>
                            <li className='px-1'>
                                <a href="https://www.linkedin.com/company/75432726/admin/"><img className='w-6' src={linkedin} alt="" /></a>
                            </li>
                            <li className='px-1'>
                                <a href="https://www.tiktok.com/@tuneprotectthailand"><img className='w-6' src={tiktok} alt="" /></a>
                            </li>
                            <li className='px-1'>
                                <a href="https://lin.ee/77HMhhU"><img className='w-6' src={line} alt="" /></a>
                            </li>
                        </ul>
                    </div>
                </div>
                {menuFooter()}
            </footer>
            <div className="hidden-md">
                <div className="grid mx-1">
                    <div className='col-2 p-3'>
                        <a href="https://www.youtube.com/@tuneprotectthailand2058"><img className="w-full" src={youtube} alt="" /></a>
                    </div>
                    <div className='col-2 p-3'>
                        <a href="https://www.facebook.com/TuneProtectThailand"><img className="w-full" src={facebook} alt="" /></a>
                    </div>
                    <div className='col-2 p-3'>
                        <a href="https://www.instagram.com/tuneprotect.thailand/"><img className="w-full" src={instragram} alt="" /></a>
                    </div>
                    <div className='col-2 p-3'>
                        <a href="https://www.linkedin.com/company/75432726/admin/"><img className="w-full" src={linkedin} alt="" /></a>
                    </div>
                    <div className='col-2 p-3'>
                        <a href="https://www.tiktok.com/@tuneprotectthailand"><img className="w-full" src={tiktok} alt="" /></a>
                    </div>
                    <div className='col-2 p-3'>
                        <a href="https://lin.ee/77HMhhU"><img className="w-full" src={line} alt="" /></a>
                    </div>
                </div>
            </div>
            <div className='copyright w-full text-center'>
                <p className="text-white">&copy; {moment().format("YYYY")} {languageSelected.toLowerCase() === 'th' ? 'ทูนประกันภัย จำกัด (มหาชน) ทะเบียนเลขที่.' : 'Tune Insurance Public Company Limited No.'}: 0107555000627 <a href="/th/privacy-policy">Privacy
                    Policy</a> | <a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/privacy-policy`}>Terms of Use</a> | <a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/ConsentManagement`}>Consent Management</a> | <a href={`${config.webUrlRedirect}/${languageSelected.toLowerCase()}/DSAR`}>Data Subject Request</a></p>
            </div>
        </>
    );
}
import moment from 'moment';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { useForm, Controller } from 'react-hook-form';
import { Calendar } from 'primereact/calendar';
import { InputMask } from "primereact/inputmask";
import cancerSlice, { postStep2, putStep2 } from '../../../../../Redux/cancerSlice';
import componentSlice, { checkPersonalBlackList } from '../../../../../Redux/componentSlice';
import { postPromotionsApplyCodeCostAmount, putPromotionsApplyCodeCostAmount } from '../../../../../Redux/promotionsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { cancerValidateApi } from '../../../../../services/api/cancer';
import { useNavigate } from 'react-router-dom';

export default function ProductCancerInformation() {

    const navigate = useNavigate();

    const { cancerText, languageSelected } = useSelector((state) => state.language);
    let { step2, warning } = cancerText

    const regInputPattern = languageSelected === 'TH' ? /^[ก-๏\s]+$/g : /^[a-zA-Z\s]+$/g
    const regInputPatternMsg = languageSelected === 'TH' ? 'โปรดกรอกภาษาไทยเท่านั้น!' : 'Must be English character!'

    const dispatch = useDispatch()
    const { cancerId, cancerStep1, documentLanguage, cancerPlanCodePricesData, cancerStep2, cancerPremium, productNameId } = useSelector((state) => state.cancer);
    const { setCancerStep2, setCancerPremium, setDataValid, setStepActive, setAccordionCancerActive } = cancerSlice.actions;
    const { promotionCode, postPromotionsApplyCodeCostAmonutData } = useSelector((state) => state.promotions)
    const { isBlockPerson } = useSelector((state) => state.component)
    const { showDailogWarning } = componentSlice.actions
    let finalAge = moment().add(-60, 'y').add(1, 'd'); // Age 60
    let minDate = new Date();
    minDate.setDate(finalAge.date());
    minDate.setMonth(finalAge.month());
    minDate.setFullYear(finalAge.year());

    let beginDate = moment().add(-18, 'y').add(1, 'd');// Age 18
    let maxDate = new Date();
    maxDate.setDate(beginDate.date());
    maxDate.setMonth(beginDate.month());
    maxDate.setFullYear(beginDate.year());

    const prefixesData = [
        {
            "titleNameId": 1,
            "code": "042",
            "desc": "นาย",
            "language": "TH"
        },
        {
            "titleNameId": 2,
            "code": "184",
            "desc": "เด็กชาย",
            "language": "TH"
        },
        {
            "titleNameId": 3,
            "code": "001",
            "desc": "MR.",
            "language": "EN"
        },
        {
            "titleNameId": 4,
            "code": "041",
            "desc": "นางสาว",
            "language": "TH"
        },
        {
            "titleNameId": 5,
            "code": "040",
            "desc": "นาง",
            "language": "TH"
        },
        {
            "titleNameId": 6,
            "code": "185",
            "desc": "เด็กหญิง",
            "language": "TH"
        },
        {
            "titleNameId": 7,
            "code": "280",
            "desc": "Miss",
            "language": "EN"
        },
        {
            "titleNameId": 8,
            "code": "002",
            "desc": "MRS.",
            "language": "EN"
        },
        {
            "titleNameId": 9,
            "code": "003",
            "desc": "MS.",
            "language": "EN"
        }
    ]

    const dropdownPrefixes = prefixesData.filter(e => e.language === languageSelected);

    if (documentLanguage !== languageSelected) {
        sessionStorage.removeItem('cancerStep1')
        sessionStorage.removeItem('cancerStep2')
        sessionStorage.removeItem('cancerStep3')
        sessionStorage.removeItem('cancerStep4')
        sessionStorage.removeItem('cancerStep5')
        sessionStorage.removeItem('cancerStep6')
        dispatch(setStepActive(0))
        dispatch(setAccordionCancerActive(0))
        navigate('/product/cancer')
    }

    const defaultValues = {
        cancerId: cancerStep2 ? cancerStep2.cancerId : 0,
        titleNameId: cancerStep2 ? dropdownPrefixes.find(e => e.titleNameId === cancerStep2.titleNameId) : '',
        firstName: cancerStep2 ? cancerStep2.firstName : '',
        lastName: cancerStep2 ? cancerStep2.lastName : '',
        middleName: cancerStep2 ? cancerStep2.middleName : '',
        birthDate: cancerStep2 ? new Date(cancerStep2.birthCalendarDate) : '',
        mobile: cancerStep2 ? cancerStep2.mobile : '',
        email: cancerStep2 ? cancerStep2.email : '',
        personalId: cancerStep2 ? cancerStep2.personalId : ''
    }

    const { control, formState: { errors }, handleSubmit, setValue } = useForm({ defaultValues });

    const calculatPremium = (data) => {
        var effective = moment().add(1, 'd');
        var birthDate = moment(data);
        var age = effective.year() - birthDate.year();
        if (birthDate > moment(effective).add(-age, 'y')) age--;
        let premium = cancerPlanCodePricesData.find(e => e.planCode === cancerStep1.planCode && e.age === age);

        let total = premium ? premium.total : 0
        dispatch(setCancerPremium(total));
        setValue('birthDate', data);

        if (promotionCode) {
            const params = {
                promotionId: postPromotionsApplyCodeCostAmonutData ? postPromotionsApplyCodeCostAmonutData.promotionId : 0,
                productNameId: productNameId,
                productId: cancerId,
                code: promotionCode,
                costAmount: total
            }

            if (postPromotionsApplyCodeCostAmonutData) {
                dispatch(putPromotionsApplyCodeCostAmount(params));
            } else {
                dispatch(postPromotionsApplyCodeCostAmount(params));
            }
        }
    }

    const checkPersolnalId = (event) => {
        if (event.target.value) {
            const params ={ personalId: event.target.value, visible: false, dailogTitle: warning.dailogWarnning.title, dailogMessage: warning.dailogWarnning.message }
            dispatch(checkPersonalBlackList(params))
        }
    }

    const onSubmit = (data) => {
        if (isBlockPerson) {
            const params ={ personalId: data.personalId, visible: false, dailogTitle: warning.dailogWarnning.title, dailogMessage: warning.dailogWarnning.message }
            dispatch(checkPersonalBlackList(params))
        } else {
            const birthDate = moment(data.birthDate)
            let birthCalendarDate = new Date();
            birthCalendarDate.setDate(birthDate.date());
            birthCalendarDate.setMonth(birthDate.month());
            birthCalendarDate.setFullYear(birthDate.year());
            let cancerValidateParam = {
                personalId: data.personalId,
                birthDate: birthDate.format("YYYY-MM-DD"),
                planCode: cancerStep1.planCode,
                costAmount: cancerPremium
            }

            cancerValidateApi(cancerValidateParam)
                .then((response) => {
                    dispatch(setDataValid(true))
                    const res = response.data;
                    if (res.status) {
                        const params = data;
                        params.cancerId = cancerId;
                        params.titleNameId = data.titleNameId.titleNameId
                        params.mobile = data.mobile.replaceAll('-', '');
                        params.birthDate = birthDate.format("YYYY-MM-DD");
                        params.birthCalendarDate = `${birthCalendarDate}`;
                        if (cancerStep2) {
                            dispatch(putStep2(params));
                        }
                        else {
                            dispatch(postStep2(params));
                        }
                    }
                }).catch((errors => {
                    dispatch(setDataValid(false))
                    dispatch(showDailogWarning({ visible: true, title: warning.dailogWarnning.title, message: warning.dailogWarnning.message }))
                }));
        }
    };

    const getFormErrorMessage = (name) => {
        return errors[name] && <div><small className="p-error">{errors[name].message}</small></div>
    };

    return (
        <div className='box-product-cancer'>
            <form id='form-campaign' onSubmit={handleSubmit(onSubmit)}>
                <h2 className='text-red-600'>{step2.information.title1}</h2>
                <div className="grid">
                    <div className="col-12 md:col-6 lg:col-6 flex flex-column gap-2">
                        <label className='font-bold text-sm'>{step2.information.prefix} <span className="text-red-600">*</span></label>
                        <Controller name="titleNameId" control={control} rules={{ required: warning.information.perfix }} render={({ field, fieldState }) => (
                            <Dropdown optionLabel="desc" autoFocus={true} options={dropdownPrefixes} placeholder={step2.information.placeholderSubDistrict} id={field.titleNameId} {...field} inputRef={field.ref} className={classNames('w-full ' + { 'p-invalid': fieldState.invalid })} />
                        )} />
                        {getFormErrorMessage('titleNameId')}
                    </div>
                </div>
                <div className="grid">
                    <div className="col-12 md:col-6 lg:col-6 flex flex-column gap-2">
                        <label className='font-bold text-sm'>{step2.information.firstName} <span className="text-red-600">*</span></label>
                        <Controller name="firstName" control={control}
                            rules={{ required: warning.information.firstname, pattern: { value: regInputPattern, message: regInputPatternMsg } }}
                            render={({ field, fieldState }) => (
                                <InputText id={field.firstName} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                            )} />
                        {getFormErrorMessage('firstName')}
                    </div>
                    <div className="col-12 md:col-6 lg:col-6 flex flex-column gap-2">
                        <label className='font-bold text-sm'>{step2.information.middleName} </label>
                        <Controller name="middleName" control={control} rules={{ pattern: { value: regInputPattern, message: regInputPatternMsg } }} render={({ field, fieldState }) => (
                            <InputText id={field.name} {...field} />
                        )} />
                        {getFormErrorMessage('middleName')}
                    </div>
                </div>
                <div className="grid">
                    <div className="col-12 md:col-6 lg:col-6 flex flex-column gap-2">
                        <label className='font-bold text-sm'>{step2.information.lastName} <span className="text-red-600">*</span></label>
                        <Controller name="lastName" control={control} rules={{ required: warning.information.lastname, pattern: { value: regInputPattern, message: regInputPatternMsg } }} render={({ field, fieldState }) => (
                            <InputText id={field.lastName} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                        )} />
                        {getFormErrorMessage('lastName')}
                    </div>
                </div>
                <div className="grid">
                    <div className="col-12 md:col-6 lg:col-6 flex flex-column gap-2">
                        <label className='font-bold text-sm'>{step2.information.birthDate} <span className="text-red-600">*</span></label>
                        <Controller name="birthDate" control={control} rules={{ required: warning.information.birthdate }} render={({ field, fieldState }) => (
                            <Calendar id={field.birthDate} {...field} className={classNames({ 'p-invalid': fieldState.invalid })}
                                minDate={minDate} maxDate={maxDate}
                                dateFormat="dd/mm/yy"
                                onChange={(e) => calculatPremium(e.value)} showIcon readOnlyInput />
                        )} />
                        {getFormErrorMessage('birthDate')}
                    </div>
                </div>

                <h2 className='text-red-600'>{step2.information.title2}</h2>
                <div className="grid">
                    <div className="col-12 md:col-6 lg:col-6 flex flex-column gap-2">
                        <label className='font-bold text-sm'>{step2.information.phone} <span className="text-red-600">*</span></label>
                        <Controller name="mobile" control={control} rules={{ required: warning.information.mobile, pattern: { value: /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/, message: warning.mobileFormat } }} render={({ field, fieldState }) => (
                            <InputMask mask="?999-999-9999" id={field.mobile} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                        )} />
                        {getFormErrorMessage('mobile')}
                    </div>
                </div>
                <div className="grid">
                    <div className="col-12 md:col-6 lg:col-6 flex flex-column gap-2">
                        <label className='font-bold text-sm'>{step2.information.email} <span className="text-red-600">*</span></label>
                        <Controller name="email" control={control}
                            rules={{ required: warning.information.email, pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: warning.information.emailFormat } }}
                            render={({ field, fieldState }) => (
                                <InputText id={field.email} {...field} keyfilter="email" className={classNames({ 'p-invalid': fieldState.invalid })} />
                            )} />
                        {getFormErrorMessage('email')}
                    </div>
                </div>

                <h2 className='text-red-600'>{step2.information.title3}</h2>
                <div className="grid">
                    <div className="col-12 md:col-6 lg:col-6 flex flex-column gap-2">
                        <label className='font-bold text-sm'>{step2.information.identify} <span className="text-red-600">*</span></label>
                        <Controller name="personalId" control={control} rules={{ required: warning.information.identify }} render={({ field, fieldState }) => (
                            <InputText id={field.personalId} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} onBlur={(e) => checkPersolnalId(e)} />
                        )} />
                        {getFormErrorMessage('personalId')}
                    </div>
                </div>
                <div className="text-center mt-3">
                    <Button className='col-12 md:col-3 lg:col-3' type='submit' label={step2.information.button} severity="danger" />
                </div>
            </form>
        </div>
    );
}
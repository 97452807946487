import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { RadioButton } from 'primereact/radiobutton';
import { classNames } from 'primereact/utils';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import cancerSlice, { postStep5, putStep5 } from '../../../../../Redux/cancerSlice';
import { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { useNavigate } from 'react-router-dom';

export default function ProductCancerHowTo() {

    const navigate = useNavigate();

    const { cancerText, languageSelected } = useSelector((state) => state.language);
    let { step2, warning } = cancerText

    const { relationsData } = useSelector((state) => state.master)
    const { cancerId, cancerStep5, documentLanguage } = useSelector((state) => state.cancer);
    const { setCancerStep5, setStepActive, setAccordionCancerActive } = cancerSlice.actions;

    const [benefitType, setBenefitType] = useState(cancerStep5 ? cancerStep5.benefitType.code : 'H');

    const benefit = [
        { label: step2.howTo.legalheir, code: 'H' },
        { label: step2.howTo.other, code: 'O' },
    ];

    const defaultValues = {
        cancerId: 0,
        docReceive: 'E',
        benefitType: cancerStep5 ? cancerStep5.benefitType : { label: step2.howTo.legalheir, code: 'H' },
        benefitFirstName: (cancerStep5 && cancerStep5.benefitType.code === 'O') ? cancerStep5.benefitFirstName : '',
        benefitLasttName: (cancerStep5 && cancerStep5.benefitType.code === 'O') ? cancerStep5.benefitLasttName : '',
        benefitRelation: (cancerStep5 && cancerStep5.benefitType.code === 'O') ? cancerStep5.benefitRelation : ''
    }

    const dispatch = useDispatch();
    const { control, formState: { errors }, handleSubmit, setValue } = useForm({ defaultValues });

    const onSubmit = (data) => {
        const params = data;
        params.cancerId = cancerId;
        params.person = benefitType === 'H' ? step2.howTo.legalheir : data.benefitFirstName + ' ' + data.benefitLasttName;
        params.relationCode = data.benefitRelation ? data.benefitRelation.code : ''
        if (cancerStep5) {
            dispatch(putStep5(params));
        }
        else {
            dispatch(postStep5(params));
        }

        dispatch(setCancerStep5(params));
    };

    if (documentLanguage !== languageSelected) {
        sessionStorage.removeItem('cancerPremium')
        sessionStorage.removeItem('cancerStep1')
        sessionStorage.removeItem('cancerStep2')
        sessionStorage.removeItem('cancerStep3')
        sessionStorage.removeItem('cancerStep4')
        sessionStorage.removeItem('cancerStep5')
        sessionStorage.removeItem('cancerStep6')
        dispatch(setStepActive(0))
        dispatch(setAccordionCancerActive(0))
        navigate('/product/cancer')
    }

    const changeBenefit = (event) => {
        setValue('benefitType', event.target.value);
        setBenefitType(event.target.value.code)
    }

    const getFormErrorMessage = (name) => {
        return errors[name] && <div><small className="p-error">{errors[name].message}</small></div>
    };

    return (
        <div className='box-product-cancer'>
            <form id='form-campaign' onSubmit={handleSubmit(onSubmit)}>
                <h2 className='text-red-600'>{step2.howTo.title1}</h2><br />
                <div className="flex flex-wrap gap-3">
                    <div className="flex align-items-center">
                        <Controller name="docReceive" control={control} rules={{ required: false }} render={({ field, fieldState }) => (
                            <RadioButton id={field.docReceive} value="E" autoFocus={true} onChange={(e) => field.onChange(e.value)} checked={field.value === 'E'} className={classNames({ 'p-invalid': fieldState.invalid })} />
                        )} />
                        <label className="font-bold ml-2">{step2.howTo.email}</label>
                    </div>
                    <div className="flex align-items-center">
                        <Controller name="docReceive" control={control} rules={{ required: false }} render={({ field, fieldState }) => (
                            <RadioButton id={field.docReceive} value="P" onChange={(e) => field.onChange(e.value)} checked={field.value === 'P'} className={classNames({ 'p-invalid': fieldState.invalid })} />
                        )} />
                        <label className="font-bold ml-2">{step2.howTo.post}</label>
                    </div>
                </div>
                <br />
                <h2 className='text-red-600'>{step2.howTo.title2}</h2>
                <div className="grid">
                    <div className="col-12 md:col-6 lg:col-6 flex flex-column gap-2">
                        <label className='font-bold text-sm'>{step2.howTo.beneficiary}</label>
                        <Controller name="benefitType" control={control} rules={{ required: warning.beneficiary.benefit }} render={({ field, fieldState }) => (
                            <Dropdown optionLabel="label" options={benefit} placeholder={step2.howTo.placeholderBeneficiary} id={field.name} {...field} onChange={(e) => changeBenefit(e)} inputRef={field.ref} className={classNames('w-full ' + { 'p-invalid': fieldState.invalid })} />
                        )} />
                        {getFormErrorMessage('benefitType')}
                    </div>
                </div>
                {(benefitType === 'O') &&
                    (<>
                        <div className="grid">
                            <div className="col-12 md:col-6 lg:col-6 flex flex-column gap-2">
                                <label className='font-bold text-sm'>{step2.howTo.firstName} <span className="text-red-600">*</span></label>
                                <Controller name="benefitFirstName" control={control} rules={{ required: warning.beneficiary.firstname }} render={({ field, fieldState }) => (
                                    <InputText id={field.benefitFirstName} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                                )} />
                                {getFormErrorMessage('benefitFirstName')}
                            </div>
                            <div className="col-12 md:col-6 lg:col-6 flex flex-column gap-2">
                                <label className='font-bold text-sm'>{step2.howTo.lastName} <span className="text-red-600">*</span></label>
                                <Controller name="benefitLasttName" control={control} rules={{ required: warning.beneficiary.lastname }} render={({ field, fieldState }) => (
                                    <InputText id={field.benefitLasttName} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                                )} />
                                {getFormErrorMessage('benefitLasttName')}
                            </div>
                        </div>
                        <div className="grid">
                            <div className="col-12 md:col-6 lg:col-6 flex flex-column gap-2">
                                <label className='font-bold text-sm'>{step2.howTo.relationship} <span className="text-red-600">*</span></label>
                                <Controller name="benefitRelation" control={control} rules={{ required: warning.beneficiary.relationship }} render={({ field, fieldState }) => (
                                    <Dropdown optionLabel="titleTh" options={relationsData} placeholder={step2.howTo.placeholderRelationship} {...field} inputRef={field.ref} className={classNames('w-full ' + { 'p-invalid': fieldState.invalid })} />
                                )} />
                                {getFormErrorMessage('benefitRelation')}
                            </div>
                        </div>
                    </>)}

                <div className="text-center mt-3">
                    <Button className='col-12 md:col-3 lg:col-3' type='submit' label={step2.howTo.button} severity="danger" />
                </div>
            </form>
        </div>
    )
}
import { Route, Routes } from 'react-router-dom'
import { useRef } from 'react'
import './style.scss'
import { Toast } from 'primereact/toast'
import Main from './Pages/Main'
import PageHome from './Pages/Main/Home'
import PageProduct from './Pages/Main/Products'
import PageProductCancer from './Pages/Main/Products/Cancer'
import PageThankyou from './Pages/Main/Thankyou'
import RenuwalThankyou from './Pages/Main/Products/Renewal/Thankyou'
import PageOops from './Pages/Main/Oops'
import ProgressLoading from './components/Progress'
import { useSelector } from 'react-redux'
import axios from 'axios'

export default function App() {

  // Add a request interceptor
  axios.interceptors.request.use(function (config) {
    // Do something before request is sent
    config.headers['authorization'] = localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : ''
    return config
  }, function (error) {
    // Do something with request error
    return Promise.reject(error)
  })

  // Add a response interceptor
  axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    if (response.config.method !== 'get') showSuccess(response.data.message)
    return response
  }, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    showError(error.response.data.message)
    return Promise.reject(error)
  })

  const { isLoading } = useSelector((state) => state.component)

  const { loadingPrefixes, loadingDistricts, loadingProvinces, loadingRelations } = useSelector((state) => state.master)

  const { loadingPlanCode,
    loadingPlanCodePrice,
    loadingStep1,
    loadingStep2,
    loadingStep3,
    loadingStep4,
    loadingStep5 } = useSelector((state) => state.cancer)

  const progressing = (isLoading ||
    loadingPrefixes ||
    loadingDistricts ||
    loadingProvinces ||
    loadingRelations ||
    loadingPlanCode ||
    loadingPlanCodePrice ||
    loadingStep1 ||
    loadingStep2 ||
    loadingStep3 ||
    loadingStep4 ||
    loadingStep5
  )

  const toast = useRef(null)

  const showSuccess = (detail) => {
    toast.current.show({ severity: 'success', summary: 'Success', detail: detail, life: 3000 })
  }

  const showError = (detail) => {
    toast.current.show({ severity: 'error', summary: 'Error', detail: detail, life: 3000 })
  }

  return (
    <>
      <Toast ref={toast} />
      {(progressing && <ProgressLoading />)}
      <Routes>
        <Route path="" element={<Main />}>
          <Route path="" element={
            <PageHome />
          } />
          <Route path="oops" element={
            <PageOops />
          } />
          <Route path="product" element={<PageProduct />}>
            <Route path="cancer" element={
              <PageProductCancer />
            } />
            <Route path="cancer/:mode" element={
              <PageProductCancer />
            } />
            <Route path="cancer/thankyou/:invoiceNo" element={
              <PageThankyou />
            } />
            <Route path="renewal/thankyou/:refcode" element={
              <RenuwalThankyou />
            } />
          </Route>
        </Route>
      </Routes>
    </>
  )
}
import { Card } from "primereact/card";
import { Carousel } from 'primereact/carousel';
import { Rating } from 'primereact/rating';
import { useSelector } from 'react-redux';

export default function HomeReview() {

    const { languageSelected, languageEng, languageThai } = useSelector((state) => state.language)
    const topic = languageSelected === 'TH' ? languageThai.review.topic : languageEng.review.topic;

    const responsiveOptions = [
        {
            breakpoint: '1199px',
            numVisible: 1,
            numScroll: 1
        },
        {
            breakpoint: '991px',
            numVisible: 2,
            numScroll: 1
        },
        {
            breakpoint: '767px',
            numVisible: 1,
            numScroll: 1
        }
    ];

    const reviewItems = [
        {
            score: 5,
            description: 'ระบบดีงาม ยอดเยี่ยม ได้เมลล์ภายใน 1 นาที',
            audience: 'คุณสุเชาว์'
        },
        {
            score: 5,
            description: 'เดินทางคนเดียวแอบกังวล แต่มี ประกันนี้ ดูแลทุกอย่าง ดีอ่ะ',
            audience: 'คุณแนน'
        },
        {
            score: 5,
            description: 'บริการดี ประทับใจมากค่ะ',
            audience: 'คุณแตงโม'
        },
        {
            score: 5,
            description: 'Brilliant service',
            audience: 'Anna'
        },
        {
            score: 5,
            description: 'ซื้อผ่านเว็บสะดวกมากครับ',
            audience: 'คุณวิน'
        },
        {
            score: 5,
            description: 'Wonderful service.',
            audience: 'Kim'
        }
    ]

    const reviewTemplate = (data) => {
        return (
            <>
                <Card>
                    <div className="p-card-content">
                        <div className="grid">
                            <div className="col-2 text-left pl-2 pr-3 number-score">
                                <h4 className="text-red">{data.score.toFixed(1)}</h4>
                            </div>
                            <div className="col-10 pt-3">
                                <Rating value={data.score} readOnly cancel={false} />
                            </div>
                        </div>
                        {data.description}
                    </div>
                    <div className="p-card-footer p-0">
                        <h5 className="text-right">{data.audience}</h5>
                    </div>
                </Card>
            </>
        );
    };

    return (
        <>
            <div className="box-review mt-3">
                <h3 className="header-box">{topic}</h3>
                <Carousel value={reviewItems} numVisible={3} numScroll={3} responsiveOptions={responsiveOptions} className="custom-carousel" circular
                    autoplayInterval={3000} itemTemplate={reviewTemplate} />
            </div>
        </>
    );
}
import axios from 'axios';
import config from '../../configs/config';

const baseUrl = window.location.origin + config.baseUrl;

export const postPaymentsGetnerateLinkApi = (params) => {
    var header = {
        method: 'post',
        url: `${baseUrl}/api/Payments/GetnerateLink`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: params
    };
    return axios(header).then((response) => response);
}
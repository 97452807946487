export const pushToDataLayer = (event, ecommerceData) => {
    if (window.dataLayer) {
        window.dataLayer.push({
            event,
            ecommerce: {
                ...ecommerceData,
            },
        });
    } else {
        console.error('DataLayer is not defined');
    }
};
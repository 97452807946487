import axios from 'axios';
import config from '../../configs/config';

const baseUrl = window.location.origin + config.baseUrl;

export const postPromotionsApplyCodeApi = (params) => {
    var header = {
        method: 'post',
        url: `${baseUrl}/api/Promotions/ApplyCode`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: params
    };
    return axios(header).then((response) => response);
}

export const postPromotionsApplyCodeCostAmountApi = (params) => {
    var header = {
        method: 'post',
        url: `${baseUrl}/api/Promotions/ApplyCodeCostAmount`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: params
    };
    return axios(header).then((response) => response);
}

export const putPromotionsApplyCodeCostAmountApi = (id, params) => {
    var header = {
        method: 'put',
        url: `${baseUrl}/api/Promotions/ApplyCodeCostAmount/${id}`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: params
    };
    return axios(header).then((response) => response);
}